import { ECategorieSocialProfessionnel } from "common/enums/ClientSheet/ECategorieSocialProfessionnel";
import { ECivility } from "common/enums/ClientSheet/ECivility";
import { ELegalCapacity } from "common/enums/ClientSheet/ELegalCapacity";
import { EMaritalStatus } from "common/enums/ClientSheet/EMaritalStatus";
import { EMatrimonialRegime } from "common/enums/ClientSheet/EMatrimonialRegime";
import { ECorporationFormeJuridique } from "common/enums/CorporationSheet/ECorporationFormeJuridique";
import { EQualiteRepresentant } from "common/enums/CorporationSheet/EQualiteRepresentant";
import { ERegimeFiscal } from "common/enums/CorporationSheet/ERegimeFiscal";
import { EResidenceFiscale } from "common/enums/CorporationSheet/EResidenceFiscale";
import { ELegalStatus } from "common/enums/LegalStatus/ELegalStatus";
import { EAssociationProfesionnelle } from "common/enums/Office/EAssociationProfesionnelle";
import { EFormeJuridique } from "common/enums/Office/EFormeJuridique";
import { EHabilitation } from "common/enums/Office/EHabilitation";
import { ELigneMetier } from "common/enums/Office/ELigneMetier";
import { ETypeConseiller } from "common/enums/Office/ETypeConseiller";
import { EProductDocumentType } from "common/enums/ProductSheet/DocumentsTemplates/EProductDocumentType";
import { EDistributionFrequency } from "common/enums/ProductSheet/EDistributionFrequency";
import { EProductFileType } from "common/enums/ProductSheet/EProductFileType";
import { EFinancialObjective } from "common/enums/ProductSubscribed/EFinancialObjective";
import { EFundsOrigin } from "common/enums/ProductSubscribed/EFundsOrigin";
import { EFundsOriginCorporation } from "common/enums/ProductSubscribed/EFundsOriginCorporation";
import { EPaymentMethod } from "common/enums/ProductSubscribed/EPaymentMethod";
import { ESubCommentConnuConsultimCorporation } from "common/enums/Scpi/Corporation/AutreCgp/ESubCommentConnuConsultimCorporation";
import { ESubModeEntreeRelationCorporation } from "common/enums/Scpi/Corporation/AutreCgp/ESubModeEntreeRelationCorporation";
import {
	ESubCombienTempsRealisezInvestissementsCorporation,
	ESubConnaissancesMarcheFinancierProduitsEpargneCorporation,
	ESubConnaissancesMarcheImmobilierCorporation,
	ESubDejaSouscritCombienCorporation,
	ESubDejaSouscritPartCorporation,
	ESubDejaSouscritQuandCorporation,
	ESubDejaSouscritTypeCorporation,
	ESubFrequenceTransactionCorporation,
	ESubModeGestionUtiliseCorporation,
	ESubMoyenneTransactionCorporation,
	ESubNbOperationsSansConseillerCorporation,
	ESubReactionChuteBrusqueValeurInvestissementCorporation,
	ESubRisqueRendementCorporation,
	ESubSupportPlacementCorporation,
} from "common/enums/Scpi/Corporation/ConnaissanceExperience";
import { ESubPartInvestissementsDurablesCorporation, ESubPreferenceDurabiliteCorporation } from "common/enums/Scpi/Corporation/FinanceDurable";
import { EAutreCgpCorporationGroups } from "common/enums/Scpi/Corporation/Groups/EAutreCgpCorporationGroups";
import { EConnaissanceExperienceCorporationGroups } from "common/enums/Scpi/Corporation/Groups/EConnaissanceExperienceCorporationGroups";
import { EFinanceDurableCorporationGroups } from "common/enums/Scpi/Corporation/Groups/EFinanceDurableCorporationGroups";
import { EPatrimoineRevenusCorporationGroups } from "common/enums/Scpi/Corporation/Groups/EPatrimoineRevenusCorporationGroups";
import { ETestAdequationCorporationGroups } from "common/enums/Scpi/Corporation/Groups/ETestAdequationCorporationGroups";
import {
	ESubCategorisationMifCorporation,
	ESubOriginePatrimoineCorporation,
	ESubPartInvestissementPatrimoineGlobalCorporation,
	ESubStatutFactaCrsCorporation,
} from "common/enums/Scpi/Corporation/Patrimoine";
import { ESubFiscaliteApplicableScpiCorporation } from "common/enums/Scpi/Corporation/TestAdequation/ESubFiscaliteApplicableScpiCorporation";
import { ESubHorizonInvestissementCorporation } from "common/enums/Scpi/Corporation/TestAdequation/ESubHorizonInvestissementCorporation";
import { ESubPerformanceScpiFonctionCorporation } from "common/enums/Scpi/Corporation/TestAdequation/ESubPerformanceScpiFonctionCorporation";
import { ESubPeriodicitePiervalCorporation } from "common/enums/Scpi/Corporation/TestAdequation/ESubPeriodicitePiervalCorporation";
import { ESubPlacementPeuLiquiditeCorporation } from "common/enums/Scpi/Corporation/TestAdequation/ESubPlacementPeuLiquiditeCorporation";
import { ESubScpiImpacteesParCorporation } from "common/enums/Scpi/Corporation/TestAdequation/ESubScpiImpacteesParCorporation";
import { ESubScpiRecourtCreditCorporation } from "common/enums/Scpi/Corporation/TestAdequation/ESubScpiRecourtCreditCorporation";
import { EProductCategories } from "common/enums/Scpi/EProductCategories";
import { ESubCommentConnuConsultim } from "common/enums/Scpi/PhysicalPerson/AutreCgp/ESubCommentConnuConsultim";
import { ESubModeEntreeRelation } from "common/enums/Scpi/PhysicalPerson/AutreCgp/ESubModeEntreeRelation";
import {
	ESubCombienTempsRealisezInvestissements,
	ESubConnaissancesMarcheFinancierProduitsEpargne,
	ESubConnaissancesMarcheImmobilier,
	ESubModeGestionUtilise,
	ESubReactionChuteBrusqueValeurInvestissement,
	ESubRisqueRendementAccepter,
	ESubScpiOpciFrequenceTransaction,
	ESubScpiOpciMoyenneTransactionCout,
	ESubScpiOpciNombreSouscriptions,
} from "common/enums/Scpi/PhysicalPerson/ConnaissanceExperience";
import { ESubNbOperationsDerniersMois } from "common/enums/Scpi/PhysicalPerson/ConnaissanceExperience/ESubNbOperationsDerniersMois";
import { ESubSupportPlacement } from "common/enums/Scpi/PhysicalPerson/ConnaissanceExperience/ESubSupportPlacement";
import { ESubPartInvestissementsDurables, ESubPreferenceDurabilite } from "common/enums/Scpi/PhysicalPerson/FinanceDurable";
import { EAutreCgpGroups } from "common/enums/Scpi/PhysicalPerson/Groups/EAutreCgpGroups";
import { EConnaissanceExprerienceGroups } from "common/enums/Scpi/PhysicalPerson/Groups/EConnaissanceExprerienceGroups";
import { EFinanceDurableGroups } from "common/enums/Scpi/PhysicalPerson/Groups/EFinanceDurableGroups";
import { EPatrimoineRevenusGroups } from "common/enums/Scpi/PhysicalPerson/Groups/EPatrimoineRevenusGroups";
import { ETestAdequationGroups } from "common/enums/Scpi/PhysicalPerson/Groups/ETestAdequationGroups";
import {
	ESubChargeAnnuelleFoyerFiscal,
	ESubInvestissementPatrimoineGlobal,
	ESubSituationPatrimoniale,
	ESubTrancheMarginaleImposition,
} from "common/enums/Scpi/PhysicalPerson/Patrimoine";
import { ESubFiscaliteApplicableScpi } from "common/enums/Scpi/PhysicalPerson/TestAdequation/ESubFiscaliteApplicableScpi";
import { ESubHorizonInvestissement } from "common/enums/Scpi/PhysicalPerson/TestAdequation/ESubHorizonInvestissement";
import { ESubPerformanceScpiFonction } from "common/enums/Scpi/PhysicalPerson/TestAdequation/ESubPerformanceScpiFonction";
import { ESubScpiImpacteesPar } from "common/enums/Scpi/PhysicalPerson/TestAdequation/ESubScpiImpacteesPar";
import { ESubScpiRecourtCredit } from "common/enums/Scpi/PhysicalPerson/TestAdequation/ESubScpiRecourtCredit";
import { ECommunityInvestment } from "common/enums/Subscription/ECommunityInvestment";
import { ESubscriptionType } from "common/enums/Subscription/ESubscriptionType";
import { ESubStep } from "common/enums/Subscription/ESubStep";
import { ESubFilesTypes } from "common/enums/Subscription/File/ESubFilesTypes";
import { EEtatCompte } from "common/enums/User/EEtatCompte";
import { EValidationError } from "common/enums/Validation/ValidationErrors";
import { EProductInformation } from "components/pages/Products/Product/elements/ProductInformation";
import { ESteps } from "components/pages/Subscriptions/corporation/CreateCorporation";

import { ECountryImplementation } from "./ECountryImplementation";
import { ENationalityImplementation } from "./ENationalityImplementation";
import { ESubDejaSouscritScpiPartPierval } from "common/enums/Scpi/PhysicalPerson/ConnaissanceExperience/ESubDejaSouscritScpiPartPierval";
import { ESubDejaSouscritScpiPartPiervalCorporation } from "common/enums/Scpi/Corporation/ConnaissanceExperience/ESubDejaSouscritScpiPartPiervalCorporation";
import { EFundsOriginCommunityInvestment } from "common/enums/Subscription/EFundsOriginCommunityInvestment";

const EPaymentMethodImplementation: Record<EPaymentMethod, string> = {
	cheque: "Chèque",
	credit: "Crédit",
	prelevement: "Prélèvement",
	virement: "Virement",
};

const ESubSituationPatrimonialeImplementation: Record<ESubSituationPatrimoniale, string> = {
	locataire: "Locataire",
	proprietaire: "Propriétaire",
	loge_titre_gratuit: "Logé(e) à titre gratuit",
};

const ESubTrancheMarginaleImpositionImplementation: Record<ESubTrancheMarginaleImposition, string> = {
	percent_0: "0%",
	percent_11: "11%",
	percent_30: "30%",
	percent_41: "41%",
	percent_45: "45%",
};

const ESubInvestissementPatrimoineGlobalImplementation: Record<ESubInvestissementPatrimoineGlobal, string> = {
	percent_between_11_20: "Entre 11% et 20%",
	percent_between_1_5: "Entre 1% et 5%",
	percent_between_6_10: "Entre 6% et 10%",
	percent_greater_20: "Supérieur à 20%",
};

const ESubPartInvestissementPatrimoineGlobalCorporationImplementation: Record<ESubPartInvestissementPatrimoineGlobalCorporation, string> = {
	percent_between_11_20: "Entre 11% et 20%",
	percent_between_1_5: "Entre 1% et 5%",
	percent_between_6_10: "Entre 6% et 10%",
	percent_greater_20: "Supérieur à 20%",
};

const ESubChargeAnnuelleFoyerFiscalImplementation: Record<ESubChargeAnnuelleFoyerFiscal, string> = {
	between_15K_25K: "Entre 15 000 € et 25 000 €",
	between_25K_50K: "Entre 25 000 € et 50 000 €",
	between_5K_15K: "Entre 5 000 € et 15 000 €",
	greater_50K: "Supérieur à 50 000 €",
	less_5K: "Inférieur à 5 000 €",
};

const EFinancialObjectiveImplementation: Record<EFinancialObjective, string> = {
	diversification: "Diversification",
	retirement_planning: "Préparation retraite",
	complementary_income: "Complément de revenus",
	portfolio_growth: "Valorisation d'un portefeuille",
	tax_optimization: "Optimisation fiscale",
};

const EFundsOriginImplementation: Record<EFundsOrigin, string> = {
	disposal_real_estate_assets: "Cession d'actifs immobiliers",
	disposal_securities_assets: "Cession de valeurs mobilières",
	donation: "Donation",
	inheritance: "Succession",
	life_insurance_buyback: "Rachat d'assurance vie",
	loan: "Emprunt",
	professional_income: "Revenus professionnels",
	savings: "Epargne",
	other: "Autres: Précisez",
};

const ESubModeGestionUtiliseImplementation: Record<ESubModeGestionUtilise, string> = {
	conseillee: "Gestion conseillée",
	direct: "Gestion directe",
	sous_mandat: "Gestion sous mandat",
};

const ESubRisqueRendementAccepterImplementation: Record<ESubRisqueRendementAccepter, string> = {
	eleve: "Élevé - Une prise de risque important (perte totale du capital)",
	faible: "Faible - Une prise de risque faible (perte minime du capital)",
	moyen: "Moyen - Une prise de risque modéré (perte de capital acceptée)",
	null: "Nul - Aucune prise de risque",
};

const ESubScpiOpciNombreSouscriptionsImplementation: Record<ESubScpiOpciNombreSouscriptions, string> = {
	moins_de_3: "Moins de 3",
	plus_de_3: "Plus de 3",
};

const ESubScpiOpciFrequenceTransactionImplementation: Record<ESubScpiOpciFrequenceTransaction, string> = {
	annuelle: "Annuelle",
	annuelle_supereur: "> 1 an",
	mensuelle: "Mensuelle",
	trimestrielle: "Trimestrielle",
};

const ESubScpiOpciMoyenneTransactionCoutImplementation: Record<ESubScpiOpciMoyenneTransactionCout, string> = {
	between_100k_300k: "Entre 100 000 € et 300 000 €",
	between_15k_50k: "Entre 15 000 € et 50 000 €",
	between_300k_500k: "Entre 300 000 € et 500 000 €",
	between_500k_1000k: "Entre 500 000 € et 1 000 000 €",
	between_50k_100k: "Entre 50 000 € et 100 000 €",
	greater_1000k: "Supérieur à 1 000 000 €",
	less_15k: "Inférieur à 15 000 €",
};

const ESubConnaissancesMarcheFinancierProduitsEpargneImplementation: Record<ESubConnaissancesMarcheFinancierProduitsEpargne, string> = {
	aucune: "Aucune",
	faible: "Faible",
	moyenne: "Moyenne",
	eleve: "Elevé",
	expert: "Expert",
};

const ESubConnaissancesMarcheFinancierProduitsEpargneTooltipImplementation: Record<ESubConnaissancesMarcheFinancierProduitsEpargne, string> = {
	aucune: "",
	faible: "Ayant une connaissance de base et pouvant prendre une décision d'investir, appuyée sur une documentation d'offre réglementée.",
	moyenne:
		"Ayant une connaissance moyenne des SCPI et pouvant prendre une décision d'investir, appuyée sur une documentation réglementée, avec la connaissance et la compréhension suffisante pour jauger des caractéristiques et des risques indiqués dans la documentation.",
	eleve: "Ayant une bonne connaissance des SCPI et ayant une solide expérience professionnelle dans le secteur financier ou étant accompagné et conseillé par des professionnels en investissement.",
	expert: "Ayant une connaissance de spécialiste des instruments financiers complexes comme, par exemple, des CFD (Contrat For Difference), et notamment des SCPI.",
};

const ESubCombienTempsRealisezInvestissementsImplementation: Record<ESubCombienTempsRealisezInvestissements, string> = {
	between_10_15_ans: "Entre 10 et 15 ans",
	between_5_10_ans: "Entre 5 et 10 ans",
	greater_15_ans: "Supérieur à 15 ans",
	less_5_ans: "Inférieur à 5 ans",
};

const ESubConnaissancesMarcheImmobilierImplementation: Record<ESubConnaissancesMarcheImmobilier, string> = {
	aucune: "Aucune",
	faible: "Faible",
	moyenne: "Moyenne",
	eleve: "Elevé",
	expert: "Expert",
};

const ESubConnaissancesMarcheImmobilierTooltipImplementation: Record<ESubConnaissancesMarcheImmobilier, string> = {
	aucune: "",
	faible: "Ayant une connaissance de base et pouvant prendre une décision d'investir, appuyée sur une documentation d'offre réglementée.",
	moyenne:
		"Ayant une connaissance moyenne des SCPI et pouvant prendre une décision d'investir, appuyée sur une documentation réglementée, avec la connaissance et la compréhension suffisante pour jauger des caractéristiques et des risques indiqués dans la documentation.",
	eleve: "Ayant une bonne connaissance des SCPI et ayant une solide expérience professionnelle dans le secteur financier ou étant accompagné et conseillé par des professionnels en investissement.",
	expert: "Ayant une connaissance de spécialiste des instruments financiers complexes comme, par exemple, des CFD (Contrat For Difference), et notamment des SCPI.",
};

const ESubReactionChuteBrusqueValeurInvestissementImplementation: Record<ESubReactionChuteBrusqueValeurInvestissement, string> = {
	conserver: "Vous les conservez dans une politique de long terme",
	investir: "Vous en profitez pour investir",
	vendre: "Vous les vendez afin de limiter vos pertes",
};

const ECivilityImplementation: Record<ECivility, string> = {
	monsieur: "Monsieur",
	madame: "Madame",
	non_communique: "Non communiqué",
};

const EMaritalStatusImplementation: Record<EMaritalStatus, string> = {
	celibataire: "Célibataire",
	divorce: "Divorcé(e)",
	marie: "Marié(e)",
	pacse: "Pacsé(e)",
	veuf: "Veuf(ve)",
	union_libre: "Union libre",
};

const ELegalCapacityImplementation: Record<ELegalCapacity, string> = {
	majeur_capable: "Majeur capable",
	majeur_sous_protection: "Majeur sous protection",
	mineur: "Mineur",
};

const EMatrimonialRegimeImplementation: Record<EMatrimonialRegime, string> = {
	communaute_reduite_aux_acquets: "Communauté réduite aux acquêts",
	communaute_meuble_et_acquets: "Communauté de meubles et acquêts (régime légal avant 66)",
	communaute_universelle: "Communauté universelle",
	separation_des_biens: "Séparation des biens",
	participation_aux_acquets: "Participation aux acquêts",
	indivision: "Indivision",
};

const ESubHorizonInvestissementImplementation: Record<ESubHorizonInvestissement, string> = {
	between_1_5_ans: "Entre 1 et 5 ans",
	between_5_10_ans: "Entre 5 et 10 ans",
	greater_10_ans: "Supérieur à 10 ans",
};

const ESubHorizonInvestissementCorporationImplementation: Record<ESubHorizonInvestissementCorporation, string> = {
	between_1_5_ans: "Entre 1 et 5 ans",
	between_5_10_ans: "Entre 5 et 10 ans",
	greater_10_ans: "Supérieur à 10 ans",
};

const ESubPartInvestissementsDurablesImplementation: Record<ESubPartInvestissementsDurables, string> = {
	between_0_25: "Entre 0% et 25%",
	between_26_50: "Entre 26% et 50%",
	between_51_75: "Entre 51% et 75%",
	between_76_100: "Entre 76% et 100%",
};

const ESubPartInvestissementsDurablesCorporationImplementation: Record<ESubPartInvestissementsDurablesCorporation, string> = {
	between_0_25: "Entre 0% et 25%",
	between_26_50: "Entre 26% et 50%",
	between_51_75: "Entre 51% et 75%",
	between_76_100: "Entre 76% et 100%",
};

const ESubPreferenceDurabiliteImplementation: Record<ESubPreferenceDurabilite, string> = {
	prise_en_compte_principales_incidences_negatives: "Prise en compte des principales incidences négatives sur les facteurs de durabilité",
	investissements_durables: "Investissements durables (Art. 2 Nr. 17 du règlement (UE) 2019/2088 - SFDR)",
	investissements_durables_sur_plan_environnemental: "Investissements durables sur le plan environnemental (Art. 2 Nr. 1 du règlement (UE) 2020/852– Règlement Taxonomie)",
};

const ESubPreferenceDurabiliteCorporationImplementation: Record<ESubPreferenceDurabiliteCorporation, string> = {
	prise_en_compte_principales_incidences_negatives: "Prise en compte des principales incidences négatives sur les facteurs de durabilité",
	investissements_durables: "Investissements durables (Art. 2 Nr. 17 du règlement (UE) 2019/2088 - SFDR)",
	investissements_durables_sur_plan_environnemental: "Investissements durables sur le plan environnemental (Art. 2 Nr. 1 du règlement (UE) 2020/852– Règlement Taxonomie)",
};

const ESubPreferenceDurabiliteTooltipImplementation: Record<ESubPreferenceDurabilite, string> = {
	prise_en_compte_principales_incidences_negatives:
		"Questions environnementales, sociales et de personnel, respect des droits de l'homme et lutte contre la corruption et les actes de corruption). Par exemple, les incidences négatives sur la durabilité liées aux émissions de gaz à effet de serre peuvent être limitées, ou encore les investissements qui impactent négativement la biodiversité peuvent être exclus.",
	investissements_durables:
		"Investissement dans une activité économique qui contribue à la réalisation d'un objectif environnemental mesuré, par exemple, par des indicateurs clés d'efficacité des ressources dans l'utilisation de l'énergie) ou d'un objectif social (par exemple, la lutte contre les inégalités sociales), à condition de ne pas causer de préjudice important à aucun de ces objectifs environnementaux ou sociaux et que les sociétés dans lesquelles l'investissement est réalisé appliquent des pratiques de bonne gouvernance.",
	investissements_durables_sur_plan_environnemental:
		"Il s'agit d'un investissement dans une ou plusieurs activités économiques qui contribuent substantiellement à la réalisation d'un ou plusieurs objectifs environnementaux (par exemple, la protection du climat, l'adaptation au changement climatique), si elles ne causent pas de préjudice important à aucun des objectifs environnementaux, si elles sont exercées dans le respect de ce que l'on appelle les garanties minimales (notamment des droits de l'Homme) et si elles sont conformes aux critères d'examen techniques établis par la Commission européenne.",
};

const ESubPreferenceDurabiliteCorporationTooltipImplementation: Record<ESubPreferenceDurabiliteCorporation, string> = {
	prise_en_compte_principales_incidences_negatives:
		"Questions environnementales, sociales et de personnel, respect des droits de l'homme et lutte contre la corruption et les actes de corruption). Par exemple, les incidences négatives sur la durabilité liées aux émissions de gaz à effet de serre peuvent être limitées, ou encore les investissements qui impactent négativement la biodiversité peuvent être exclus.",
	investissements_durables:
		"Investissement dans une activité économique qui contribue à la réalisation d'un objectif environnemental mesuré, par exemple, par des indicateurs clés d'efficacité des ressources dans l'utilisation de l'énergie) ou d'un objectif social (par exemple, la lutte contre les inégalités sociales), à condition de ne pas causer de préjudice important à aucun de ces objectifs environnementaux ou sociaux et que les sociétés dans lesquelles l'investissement est réalisé appliquent des pratiques de bonne gouvernance.",
	investissements_durables_sur_plan_environnemental:
		"Il s'agit d'un investissement dans une ou plusieurs activités économiques qui contribuent substantiellement à la réalisation d'un ou plusieurs objectifs environnementaux (par exemple, la protection du climat, l'adaptation au changement climatique), si elles ne causent pas de préjudice important à aucun des objectifs environnementaux, si elles sont exercées dans le respect de ce que l'on appelle les garanties minimales (notamment des droits de l'Homme) et si elles sont conformes aux critères d'examen techniques établis par la Commission européenne.",
};

const ESubModeEntreeRelationImplementation: Record<ESubModeEntreeRelation, string> = {
	rdv_distance: "Rendez-vous à distance",
	rdv_physique: "Rendez-vous physique",
};

const ESubModeEntreeRelationCorporationImplementation: Record<ESubModeEntreeRelationCorporation, string> = {
	rdv_distance: "Rendez-vous à distance",
	rdv_physique: "Rendez-vous physique",
};

const ESubStepCGPImplementation: Record<ESubStep, string> = {
	draft: "En cours",
	processing: "En cours",
	waiting_signature: "En attente de signature",
	waiting_validation: "En cours de traitement",
	validated: "Validées",
};

const ESubStepMOImplementation: Record<ESubStep, string> = {
	draft: "Brouillon",
	processing: "À traiter",
	waiting_signature: "En attente de signature",
	waiting_validation: "En cours de validation de la société de gestion",
	validated: "Validées",
};

const EValidationErrorImplementation: Record<EValidationError, string> = {
	isArray: "Vous devez sélectionner au moins une valeur.",
	isBoolean: "Ce champ est obligatoire.",
	isEnum: "Ce champ est obligatoire.",
	isInt: "Assurez-vous d'entrer un nombre entier, sans décimales.",
	isNotEmpty: "Ce champ ne doit pas être vide.",
	isNumber: "Ce champ est obligatoire. La valeur doit être un nombre.",
	isObject: "Ce champ est obligatoire.",
	isString: "Ce champ est obligatoire.",
	max: "Le nombre doit être inférieur ou égal à {{var1}}.",
	min: "Le nombre doit être supérieur ou égal à {{var1}}.",
	badCredentials: "Identifiants incorrects. Veuillez réessayer.",
	clientNotExists: "Le client n'existe pas.",
	clientSheetNotExists: "La fiche client n'existe pas.",
	oneClientSheetNotExists: "Une des fiches clients n'existe pas.",
	emailNotExists: "L'email n'existe pas.",
	isEmail: "Veuillez entrer une adresse e-mail valide.",
	isNumberString: "Ce champ est obligatoire. La valeur doit être numérique.",
	isPhoneNumber: "Veuillez entrer un numéro de téléphone valide.",
	isPositive: "Le nombre doit être positif.",
	maxLength: "La longueur du texte ne doit pas dépasser {{var1}} caractères.",
	mustBeInThePast: "Assurez-vous que la date est dans le passé.",
	passwordNotMatch: "Les mots de passe ne correspondent pas",
	isStrongPassword: "Le mot de passe doit être plus complexe pour des raisons de sécurité.",
	pinCodeNotValid: "Le code PIN n'est pas correct.",
	productNotExists: "Le produit n'existe pas.",
	productSubscribedNotExists: "Le produit souscrit n'existe pas.",
	roleNotExists: "Le rôle que vous avez sélectionné n'existe pas.",
	uniqueEmail: "Veuillez utiliser une adresse e-mail différente, celle-ci est déjà utilisée.",
	wrongPassword: "Le mot de passe que vous avez saisi est incorrect. Veuillez réessayer.",
	arrayNotEmpty: "Ce champ est obligatoire.",
	productSheetNotExists: "La fiche produit n'existe pas.",
	subscriberNotSame: "Le souscripteur doit être le même sur tous les produits",
	subscriptionNotExists: "La souscription n'existe pas.",
	match: "Les valeurs ne correspondent pas.",
	uniqueSiret: "Ce siret est déjà renseigné pour un autre cabinet.",
	officeNotExists: "Ce Cabinet n'existe pas",
	minLength: "La longueur du texte doit être d'au moins {{var1}} caractères.",
	isIBAN: "Le format de l'IBAN saisi est invalide",
	isDate: "Ce champ est obligatoire.",
	isBIC: "Le format du BIC saisi est invalide",
	productNotAvailable: "Le produit n'est pas disponible",
	emailNotFound:
		"L'adresse e-mail que vous avez fournie n'est associée à aucun compte ou a été désactivée.\n\nSi vous pensez qu’il s’agit d’une erreur, veuillez nous contacter à backoffice@wenimmo.com",
	emailCaseSensitive: "L'adresse e-mail doit être en minuscules",
	percentageTotalMustBe100: "Le total des pourcentages doit être égal à 100",
	fileNotExists: "Le fichier n'existe pas",
	notInAdequationWithInvestment: "La réponse apportée ne semble pas en adéquation avec l'investissement souhaité",
	mustIncludeBicOrIrppOrBnc: "Vous devez sélectionner au moins un des régimes fiscaux suivants: BIC, IRPP ou BNC",
	personUSmustBeFalse: "La personne ne doit pas être un citoyen américain",
	corporationNotExists: "La société n'existe pas",
	blockOperation: "Contacter Wenimmo, backoffice@wenimmo.com, pour cette opération",
	mustBeLessOrEqualChildren: "Le nombre d'enfants à charge doit être inférieur ou égal au nombre d'enfants",
};

const EOtherErrorsImplementation: Record<string, string> = {
	revenuCharge_error: "Veuillez indiquer le montant et la nature de la charge.",
};

const ETypeConseillerImplementation: Record<ETypeConseiller, string> = {
	assurance: "Assurance",
	autre: "Autre",
	banque: "Banque",
	conseiller_en_investissement_financier: "Conseiller en investissement financier",
	courtier_en_assurance: "Courtier en assurance",
	groupement: "Groupement",
};

const EHabilitationImplementation: Record<EHabilitation, string> = {
	carte_t: "Carte T",
	CIF: "CIF",
	courtier_en_assurance: "Courtier en assurance",
	demarchage_bancaire_et_financier: "Démarchage bancaire et financier",
	IOBSP: "IOBSP",
	PSI: "PSI",
};

const ELigneMetierImplementation: Record<ELigneMetier, string> = {
	assurance: "Assurance",
	banque: "Banque",
	cif: "CIF",
};

const EEtatCompteImplementation: Record<EEtatCompte, string> = {
	actif: "Actif",
	inactif: "Inactif",
};

const EFormeJuridiqueImplementation: Record<EFormeJuridique, string> = {
	auto_entrepreneur: "Auto-entrepreneur",
	EI: "EI - Entreprise individuelle",
	EURL: "EURL - Entreprise Unipersonnelle à Responsabilité Limitée",
	SA: "SA - Société Anonyme",
	SARL: "SARL - Société à Responsabilité Limitée",
	SAS: "SAS - Société par Actions Simplifiée",
	SASU: "SASU - Société par Actions Simplifiée Unipersonnelle",
	other: "Autre",
};

const EAssociationProfesionnelleImplementation: Record<EAssociationProfesionnelle, string> = {
	ANACOFI_CIF: "ANACOFI-CIF",
	Aucune_association: "Autre association",
	CNCEF: "CNCEF",
	CNCGP: "CNCGP",
	LA_COMPAGNIE_DES_CGP_CIF: "La Compagnie des CGP-CIF",
};

const ESubCommentConnuConsultimImplementation: Record<ESubCommentConnuConsultim, string> = {
	autre: "Autre",
	connseillerFinancier: "Conseiller Financier ou Gestion de patrimoine",
	email: "Email",
	internet: "Internet",
	presse: "Article de presse",
	publicitePresse: "Publicité presse",
	recommandation: "Recommandation (ami, famille, collègue)",
};

const ESubCommentConnuConsultimCorporationImplementation: Record<ESubCommentConnuConsultimCorporation, string> = {
	autre: "Autre",
	connseillerFinancier: "Conseiller Financier ou Gestion de patrimoine",
	email: "Email",
	internet: "Internet",
	presse: "Article de presse",
	publicitePresse: "Publicité presse",
	recommandation: "Recommandation (ami, famille, collègue)",
};

const ESubNbOperationsDerniersMoisImplementation: Record<ESubNbOperationsDerniersMois, string> = {
	more_then_ten: "Plus de 10",
	one_to_five: "De 1 à 5",
	none: "Aucune",
	six_to_ten: "De 6 à 10",
};

const ESubPerformanceScpiFonctionImplementation: Record<ESubPerformanceScpiFonction, string> = {
	desDeux: "Des deux",
	dividendeDistribue: "Du dividende distribué",
	evolutionPrixPart: "De l'évolution du prix de la part",
};

const ESubPerformanceScpiFonctionCorporationImplementation: Record<ESubPerformanceScpiFonctionCorporation, string> = {
	desDeux: "Des deux",
	dividendeDistribue: "Du dividende distribué",
	evolutionPrixPart: "De l'évolution du prix de la part",
};

const ESubScpiRecourtCreditImplementation: Record<ESubScpiRecourtCredit, string> = {
	neSaisPas: "Je ne sais pas",
	non: "Non",
	oui: "Oui",
};

const ESubScpiRecourtCreditCorporationImplementation: Record<ESubScpiRecourtCreditCorporation, string> = {
	neSaisPas: "Je ne sais pas",
	non: "Non",
	oui: "Oui",
};

const ESubScpiImpacteesParImplementation: Record<ESubScpiImpacteesPar, string> = {
	desDeux: "Des deux",
	evolutionLoyer: "L'évolution des loyers",
	evolutionMarcheImmo: "L'évolution du marché immobilier",
};

const ESubScpiImpacteesParImplementationCorporation: Record<ESubScpiImpacteesParCorporation, string> = {
	desDeux: "Des deux",
	evolutionLoyer: "L'évolution des loyers",
	evolutionMarcheImmo: "L'évolution du marché immobilier",
};

const ESubFiscaliteApplicableScpiImplementation: Record<ESubFiscaliteApplicableScpi, string> = {
	dividendes: "Il s'agit essentiellement de la fiscalité des dividendes",
	neSaisPas: "Je ne sais pas",
	pasDeFiscalite: "Il n'y a pas de fiscalité sur les revenus perçus",
	revenusFonciers: "Il s'agit essentiellement de la fiscalité des revenus fonciers",
};

const ESubFiscaliteApplicableScpiImplementationCorporation: Record<ESubFiscaliteApplicableScpiCorporation, string> = {
	dividendes: "Il s'agit essentiellement de la fiscalité des dividendes",
	neSaisPas: "Je ne sais pas",
	pasDeFiscalite: "Il n'y a pas de fiscalité sur les revenus perçus",
	revenusFonciers: "Il s'agit essentiellement de la fiscalité des revenus fonciers",
};

const EProductCategoriesImplementation: Record<EProductCategories, string> = {
	activity_logistic: "Activité/Logistique",
	diversified: "Diversifiée",
	health: "Santé et éducation",
	office: "Bureaux",
	regional_diversified: "Diversifiée régionale",
	residential: "Résidentielle",
	retail: "Commerces",
	tourism: "Tourisme et loisirs",
};

const ETestAdequationGroupsImplementation: Record<ETestAdequationGroups, string> = {
	horizonInvestissement: "Selon votre connaissance et votre compréhension du produit SCPI, quel serait votre horizon de placement d'investissement ?",
	informationModifierSituationFinanciere:
		"Informations ou projets futurs qui pourraient modifier sensiblement votre situation financière voire nécessiter un rachat de parts préalablement à l'horizon d'investissement recommandé de 10 ans",
	avantagesRisquesSCPI:
		"Compte tenu des documents et des renseignements qui vous ont été délivrés, estimez-vous avoir compris les avantages et le risques inhérents aux placement en parts de SCPI? ",
	modalitesAchatPartsSCPI:
		"Connaissez-vous les modalités d'achat de parts de SCPI (notamment le délai de jouissance,la fréquence de versment des dividendes, la signature d'un bulletin de souscription)",
	modalitesVentePartsSCPI:
		"Connaissez-vous les modalités de vente de parts de SCPI (et notamment le risque de faible liquidité : la cession de parts ne pouvant être effective qu'en cas de contrepartie d'une souscription correspondante, la revente n'est pas garantie et le capital pourra ne pas être disponible immédiatement)",
	accepterPlacementLongTerme: "La SCPI est un investissement immobilier. Acceptez-vous d'envisager ce placement sur le long terme (> 12 ans) ?",
	accepterNonGarantieCapital:
		"La SCPI ne garantit pas le capital investi. Avez-vous compris et acceptez-vous que les parts de SCPI ne comportent pas de garantie en capital et dépend du marché immobilier ? ",
	accepterRisqueLiquiditeReduite:
		"Avez-vous compris et intégré que le caractère immobilier du placement en parts de SCPI lui confère une liquidité réduite comparée à celle des actifs financiers (livret bancaire ou actions par exemple) ? ",
	accepterRendementNonGaranti:
		"Avez-vous compris et intégré que le rendement des SCPI n'est pas garanti et que le dividende des SCPI peut fluctuer à la hausse comme à la baisse ? ",
	performanceScpiFonction: "Selon vous, pour un investisseur la performance d'une SCPI est en fonction :",
	scpiRecourtCredit: "Selon vous, les SCPI peuvent-elle recourir à un crédit pour leurs acquisitions immobilières ?",
	scpiImpacteesPar: "Selon vous, les SCPI sont-elles impactées par :",
	fiscaliteApplicableScpi: "Quelle est la fiscalité applicable aux dividendes perçus par les associés d'une SCPI ?",
};

const ETestAdequationCorporationGroupsImplementation: Record<ETestAdequationCorporationGroups, string> = {
	informationModifierSituationFinanciereCorporation:
		"Informations ou projets futurs qui pourraient modifier sensiblement votre situation financière voire nécessiter un rachat de parts préalablement à l'horizon d'investissement recommandé de 10 ans",
	horizonInvestissementCorporation: "Selon votre connaissance et votre compréhension du produit SCPI, quel serait votre horizon de placement d'investissement ?",
	avantagesRisquesSCPICorporation:
		"Compte tenu des documents et des renseignements qui vous ont été délivrés, estimez-vous avoir compris les avantages et le risques inhérents aux placement en parts de SCPI? ",
	modalitesAchatPartsSCPICorporation:
		"Connaissez-vous les modalités d'achat de parts de SCPI (notamment le délai de jouissance,la fréquence de versment des dividendes, la signature d'un bulletin de souscription)",
	modalitesVentePartsSCPICorporation:
		"Connaissez-vous les modalités de vente de parts de SCPI (et notamment le risque de faible liquidité : la cession de parts ne pouvant être effective qu'en cas de contrepartie d'une souscription correspondante, la revente n'est pas garantie et le capital pourra ne pas être disponible immédiatement)",
	accepterPlacementLongTermeCorporation: "La SCPI est un investissement immobilier. Acceptez-vous d'envisager ce placement sur le long terme (> 12 ans) ?",
	accepterNonGarantieCapitalCorporation:
		"La SCPI ne garantit pas le capital investi. Avez-vous compris et acceptez-vous que les parts de SCPI ne comportent pas de garantie en capital et dépend du marché immobilier ? ",
	accepterRendementNonGarantiCorporation:
		"Avez-vous compris et intégré que le rendement des SCPI n'est pas garanti et que le dividende des SCPI peut fluctuer à la hausse comme à la baisse ? ",
	performanceScpiFonctionCorporation: "Selon vous, pour un investisseur la performance d'une SCPI est en fonction :",
	scpiRecourtCreditCorporation: "Selon vous, les SCPI peuvent-elle recourir à un crédit pour leurs acquisitions immobilières ?",
	scpiImpacteesParCorporation: "Selon vous, les SCPI sont-elles impactées par :",
	fiscaliteApplicableScpiCorporation: "Quelle est la fiscalité applicable aux dividendes perçus par les associés d'une SCPI ?",
	periodicitePiervalCorporation: "Selon vous, selon quelle périodicité sont versés les dividendes de la SCPI PIERVAL SANTE",
	placementPeuLiquiditeCorporation: "Selon vous, quel est le placement qui présente peu de liquidité parmi les propositions suivantes ? ",
};

const EPatrimoineRevenusGroupsImplementation: Record<EPatrimoineRevenusGroups, string> = {
	nbEnfantsACharge: "Nombre d'enfants",
	montantImpotAnneePrecedente: "Montant de l'impôt N-1",
	trancheMarginaleImposition: "Tranche marginale d'imposition",
	montantIFIAnneePrecedente: "Montant de l'IFI N-1",
	situationPatrimoniale: "Situation patrimoniale",
	patrimoine: "Patrimoine",
	compositionPatrimoine: "Composition du patrimoine",
	originePatrimoine: "Origine du patrimoine",
	revenusAnnuelsBrutsFoyer: "Revenus annuels bruts du foyer",
	compositionRevenus: "Composition du revenu",
	capaciteEpargneMensuelle: "Votre situation vous permet d'épargner quelle somme tous les mois ?",
	investissementPatrimoineGlobal: "Part de cet investissement dans votre patrimoine global",
	montantGlobalPretRembourser: "En cas de prêt immobilier, montant global du prêt à rembourser ",
	revenuCharge: "Charges mensuelles",
	tauxEndettement: "En cas d'emprunt/dettes en cours, quel est votre taux d'endettement",
	chargesAnnuellesFoyerFiscal: "Charges annuelles du foyer fiscal",
	creditConsommation: "Crédit à la consommation",
	creditImmobilierInvestissementLocatif: "Crédit immobilier pour un investissement locatif",
	loyerOuCreditMensuel: "Loyer ou crédit mensuel",
};

const EPatrimoineRevenusCorporationGroupsImplementation: Record<EPatrimoineRevenusCorporationGroups, string> = {
	nbSalariesCorporation: "Nombre de salariés",
	montantImpotAnneePrecedenteCorporation: "Montant de l'impôt N-1",
	montantIFIAnneePrecedenteCorporation: "Montant de l'IFI N-1",
	patrimoineCorporation: "Patrimoine de la société",
	compositionPatrimoineCorporation: "Composition du patrimoine",
	situationFinanciereCorporation: "Situation financière (N-1)",
	originePatrimoineCorporation: "Origine du patrimoine",
	revenusAnnuelsBrutsCorporation: "Revenus annuels bruts (chiffre d'affaire N-1)",
	compositionRevenusSocieteCorporation: "Composition du revenu de la société",
	situationFinancierePermetPlacerCorporation: "La situation financière de votre société lui permet-elle de placer une partie de son résultat ?",
	partInvestissementPatrimoineGlobalCorporation: "Part de cet investissement dans le patrimoine global",
	chargeEmpruntImmobilierAnnuelleCorporation: "Charge d'emprunt immobilier annuelle",
	chargeAutreEmpruntAnnuelleCorporation: "Charge d'autre emprunt annuelle",
	categorisationMifCorporation: "Categorisation MIF",
	societeCoteeCorporation: "La société est-elle cotée ou filale d'une société cotée?",
	societeListeeCodeMonetaireCorporation: "Société listée à l'article L.561-2 du Code monétaire et financier",
	societeUsPersonCorporation: 'La société est-elle considérée comme une "us person" au sens de la règlementation américaine?',
	statutFactaCrsCorporation: "Statut fatca et crs",
	detientPlusDroitsVoteCorporation: "La société ou son groupe est-elle domiciliée ou a-t-elle un bureau dans un MSC (pays faisant l'objet de sanctions internationales) ? ",
	activeEnDehorsUeCorporation: "La société a-t'elle une activité en dehors de l'union européenne ?",
	secteurActiviteCorporation: "Secteur d'activité",
};

const EFinanceDurableGroupsImplementation: Record<EFinanceDurableGroups, string> = {
	familierInvestissementResponsable: "Êtes-vous familier du terme « investissement responsable » ?",
	importanceEngagementsEnvironnementaux: "Donnez-vous de l'importance aux engagements environnementaux pris par la société dans laquelle vous investissez ?",
	partInvestissementsDurables: "Quelle part de vos investissements souhaitez-vous allouer à des actifs qualifiés d'investissements durables ?",
	durabiliteCriterePrimordial: "Dans le cadre de vos investissements, la durabilité est-elle le critère primordial ?",
	preferenceDurabilite:
		"Si oui, avez-vous des préférences en matière de durabilité sur l'un ou plusieurs des aspects de durabilité suivants ? Veuillez cocher les cases qui correspondent à votre/vos préférence(s)",
};

const EFinanceDurableCorporationGroupsImplementation: Record<EFinanceDurableCorporationGroups, string> = {
	familierInvestissementResponsableCorporation: "Êtes-vous familier du terme « investissement responsable » ?",
	importanceEngagementsEnvironnementauxCorporation: "Donnez-vous de l'importance aux engagements environnementaux pris par la société dans laquelle vous investissez ?",
	partInvestissementsDurablesCorporation: "Quelle part de vos investissements souhaitez-vous allouer à des actifs qualifiés d'investissements durables ?",
	durabiliteCriterePrimordialCorporation: "Dans le cadre de vos investissements, la durabilité est-elle le critère primordial ?",
	preferenceDurabiliteCorporation:
		"Si oui, avez-vous des préférences en matière de durabilité sur l'un ou plusieurs des aspects de durabilité suivants ? Veuillez cocher les cases qui correspondent à votre/vos préférence(s)",
};

const EConnaissanceExperienceGroupsImplementation: Record<EConnaissanceExprerienceGroups, string> = {
	dejaSouscritScpi: "Avez-vous déjà souscrit des SCPI",
	nbSouscriptionsScpi: "Nombre de souscriptions au cours des 12 derniers mois",
	datePremiereSouscription: "Date approximative de la première souscription",
	dateDerniereSouscription: "Date approximative de la dernière souscription",
	experienceProfessionnelle:
		"Avez-vous une expérience professionnelle d'au moins un an vous permettant d'acquérir une bonne connaissance des marchés financiers et/ou immobiliers ? ",
	supportPlacement: "Indiquez les supports de placement",
	modeGestionUtilise: "Mode de gestion auquel vous avez recours",
	nbOperationsDerniersMois: "Nombre d'opérations réalisées sans conseiller au cours des 12 derniers mois",
	risqueRendementAccepter: "Quel degré de risque/rendement êtes-vous prêt à accepter ?",
	connaissancesMarcheFinancierProduitsEpargne: "Comment qualifierez-vous votre niveau de connaissances du marché financier et des produits d'épargne?",
	combienTempsRealisezInvestissements: "Depuis combien de temps réalisez-vous des investissements",
	dejaPertePlacement: "Avez-vous déjà été en situation de perte sur vos placements financiers ?",
	reactionChuteBrusqueValeurInvestissement: "Quelle serait votre réaction si la valeur de vos investissements venait à chuter brusquement ?",
	connaissancesMarcheImmobilier: "Comment qualifierez-vous votre niveau de connaissances du marché et des produits immobiliers ? ",
	scpiOpciNombreSouscriptions: "Nombre de souscriptions de parts de SCPI",
	dejaSouscritScpiNamePierval: "Précisez le nom des SCPI",
	dejaSouscritScpiPartPierval: "Quelle part représentent les SCPI dans votre patrimoine ?",
};

const EConnaissanceExperienceCorporationGroupsImplementation: Record<EConnaissanceExperienceCorporationGroups, string> = {
	experienceProfessionnelleCorporation:
		"Avez-vous une expérience professionnelle d'au moins un an vous permettant d'acquérir une bonne connaissance des marchés financiers et/ou immobiliers ? ",
	supportPlacementConnaissezCorporation: "Indiquez les supports de placement que vous connaissez",
	supportPlacementPossedezCorporation: "Indiquez les supports de placement que vous possédez",
	dejaSouscritScpiCorporation: "Avez-vous déjà souscrit des parts de SCPI",
	dejaSouscritScpiNamePiervalCorporation: "Précisez le nom des SCPI",
	dejaSouscritScpiPartPiervalCorporation: "Quelle part représentent les SCPI dans votre patrimoine ?",
	dejaSouscritScpiQuandCorporation: "Quand ?",
	dejaSouscritScpiCombienCorporation: "Combien ?",
	dejaSouscritScpiPremiereSouscriptionCorporation: "Date approximative de la première souscription",
	dejaSouscritScpiTypeCorporation: "Quel type de SCPI possédez-vous ?",
	dejaSouscritScpiPartCorporation: "Quelle part représente ce portefeuille dans votre patrimoine ?",
	frequenceTransactionCorporation: "Fréquence de la transaction",
	moyenneTransactionCorporation: "Moyenne de transaction en €",
	modeGestionCorporation: "Mode de gestion auquel vous avez recours",
	nbOperationsSansConseillerCorporation: "Nombre d'opérations réalisées sans conseiller au cours des 12 derniers mois",
	risqueRendementCorporation: "Quel degré de risque/rendement êtes-vous prêt à accepter ?",
	connaissancesMarcheFinancierProduitsEpargneCorporation: "Comment qualifierez-vous votre niveau de connaissances du marché financier et des produits d'épargne?",
	combienTempsRealisezInvestissementsCorporation: "Depuis combien de temps réalisez-vous des investissements ?",
	dejaPertePlacementCorporation: "Avez-vous déjà été en situation de perte sur vos placements financiers ?",
	reactionChuteBrusqueValeurInvestissementCorporation: "Quelle serait votre réaction si la valeur de vos investissements venait à chuter brusquement ?",
	connaissancesMarcheImmobilierCorporation: "Comment qualifierez-vous votre niveau de connaissances du marché et des produits immobiliers ? ",
};

const EAutreCgpGroupsImplementation: Record<EAutreCgpGroups, string> = {
	appreciationClient: "Votre appréciation sur le client",
	appreciationOperation: "Votre appréciation sur l'opération",
	modeEntreeRelation: "Mode d'entrée en relation",
	commentConnuConsultim: "Comment avez-vous connu Consultim Asset Management ?",
	dateEntreeRelation: "Date d'entrée en relation",
	coherenceSituationPatrimonialeEtInvestissement: "Coherence entre la situation patrimoniale et l'investissement",
};

const EAutreCgpCorporationGroupsImplementation: Record<EAutreCgpCorporationGroups, string> = {
	appreciationClientCorporation: "Votre appréciation sur le client",
	appreciationOperationCorporation: "Votre appréciation sur l'opération",
	commentConnuConsultimCorporation: "Comment avez-vous connu Consultim Asset Management ?",
	modeEntreeRelationCorporation: "Mode d'entrée en relation",
	dateEntreeRelationCorporation: "Date d'entrée en relation",
	coherenceSituationPatrimonialeEtInvestissementCorporation: "Coherence entre la situation patrimoniale et l'investissement",
};

const ESubFilesTypesImplementation: Record<ESubFilesTypes, string> = {
	avis_imposition: "Avis d'imposition",
	carte_id: "Carte d'identité",
	carte_id_spouse: "Carte d'identité du conjoint",
	justificatif_domicile: "Justificatif de domicile",
	justificatif_origine_fonds: "Justificatif de l'origine des fonds",
	others: "Autres",
	rib: "RIB de perception des dividendes",
	rib_prelevement: "RIB pour prélèvement",
	product_subscribed: "Produit souscrit",
	scpi: "SCPI",
	rib_corporation: "RIB de perception des dividendes",
	rib_prelevement_corporation: "RIB pour prélèvement",
	kbis: "Kbis infogreffe (de moins de 3 mois) *",
	certified_status: "Statuts certifiés conformes (de moins de 3 mois) *",
	card_id_legal_representative: "Carte d'identité du représentant légal",
	justificatif_domicile_legal_representative: "Justificatif de domicile du représentant légal",
	beneficiaries_list: "Liste des bénéficiaires effectifs",
	justificatif_origine_fonds_corporation: "Justificatif de l'origine des fonds",
	beneficiaries_id: "Carte d'identité des bénéficiaires effectifs",
	beneficiaries_justificatif_domicile: "Justificatif de domicile des bénéficiaires effectifs",
	liasse_fiscale: "Liasse fiscale ou formulaire 2072 (le cas échéant)",
};

const EDistributionFrequencyImplementation: Record<EDistributionFrequency, string> = {
	monthly: "Mensuelle",
	quarterly: "Trimestrielle",
	annual: "Annuelle",
	semi_annual: "Semestrielle",
	no_payout: "Pas de versement",
};

const ECommunityInvestmentImplementation: Record<ECommunityInvestment, string> = {
	reinvestment_of_equity: "Un réemploi de fonds propres",
	mutual_funds: "Des fonds communs",
};

const ECommunityInvestmentTooltipImplementation: Record<ECommunityInvestment, string> = {
	reinvestment_of_equity: "Certaines sociétés de gestion sont susceptibles de demander une attestation complémentaire signée par les 2 conjoints",
	mutual_funds: "Certaines sociétés de gestion sont susceptibles de demander soit une attestation de renonciation du conjoint, soit la signature du conjoint sur le bulletin",
};

const ELegalStatusImplementation: Record<ELegalStatus, string> = {
	natural: "Personne physique",
	legal: "Personne morale",
};

const ECorporationStepsImplementation: Record<ESteps, string> = {
	CORPORATION_INFO: "Informations société",
	LEGAL_REPRESENTATIVE: "Représentant légal",
	BENEFICIARIES: "Bénéficiaires effectifs",
};

const ECorporationRegimeFiscalImplementation: Record<ERegimeFiscal, string> = {
	is: "IS",
	ir: "IR",
	nonAssujetti: "Non assujetti (association)",
};

const ECorporationResidenceFiscaleImplementation: Record<EResidenceFiscale, string> = {
	franceDomTom: "France et DOM-TOM",
	UE: "UE",
	horsUE: "Hors UE",
};

const ECorporationFormeJuridiqueImplementation: Record<ECorporationFormeJuridique, string> = {
	association: "Association",
	congregation: "Congrégation",
	EARL: "EARL",
	EI: "Entreprise individuelle",
	fondation: "Fondation",
	SA: "Société anonyme",
	SARL: "Société à responsabilité limitée",
	SAS: "Société par action simplifiée",
	SASU: "Société par action simplifiée unipersonnelle",
	SC: "Société civile",
	SCI: "Société civile immobilière",
	SCP: "Société civile de professionnels",
	SCS: "Société en commandite simple",
	SEL: "Société d'exercice libéral",
	SELARL: "Société d'exercice libéral à responsabilité limitée",
	SNC: "Société en nom collectif",
	SCCV: "Société civile de construction vente",
	SCM: "Société civile de moyens",
	SELAS: "Société d'exercice libéral par actions simplifiée",
	other: "Autre",
};

const EQualiteRepresentantImplementation: Record<EQualiteRepresentant, string> = {
	president: "Président(e)",
	gerant: "Gérant(e)",
	signataire: "Signataire",
};

const ECategorieSocialProfessionnelImplementation: Record<ECategorieSocialProfessionnel, string> = {
	agriculteur_exploitant: "Agriculteurs exploitants / Agricultrices exploitantes",
	artisan_commercant: "Artisans / Artisanes, commerçants / commerçantes et chefs / cheffes d'entreprise",
	cadre: "Cadres et professions intellectuelles supérieures",
	ouvrier: "Ouvriers / Ouvières",
	profession_intermediaire: "Professions intermédiaires",
	employe: "Employés / Employées",
	sans_activite: "Sans activité professionnelle",
	retraite: "Retraités / Retraitées",
	liberal: "Professions libérales",
};

const ESubCategorisationMifCorporationImplementation: Record<ESubCategorisationMifCorporation, string> = {
	professionnel: "Professionnel",
	non_professionnel: "Non professionnel",
};

const ESubStatutFactaCrsCorporationImplementation: Record<ESubStatutFactaCrsCorporation, string> = {
	enf_active_autre: "ENF - Entité non financière active - autre : revenus issus de votre activité industrielle, artisinale, commerciale ou libérale",
	enf_active_bourse: "ENF - Entité non financière active - société côté en bourse",
	enf_active_gouvernementale: "ENF - Entité non financière active - entité gouvernementale",
	enf_passive: "ENF - Entité non financière passive (Vos revenus sont majoritairement issus de revenus passifs (loyers, dividendes, rentes…)",
	if_declarante:
		"IF - Entité Financière déclarante (établissement gérant des dépôts de titres, un Etablissement de dépôt, une Entité d'investissement ou un Organisme d'assurance particulier)",
	if_non_declarante:
		"IF - Entité Financière non-déclarante (établissement gérant des dépôts de titres, un Etablissement de dépôt, une Entité d'investissement ou un Organisme d'assurance particulier)",
	if_pays_non_crs:
		"IF - Entité Financière située dans un pays non signataire de CRS (établissement gérant des dépôts de titres, un Etablissement de dépôt, une Entité d'investissement ou un Organisme d'assurance particulier)",
};

const ESubPeriodicitePiervalCorporationImplementation: Record<ESubPeriodicitePiervalCorporation, string> = {
	mensuelle: "Mensuelle",
	trimestrielle: "Trimestrielle",
	annuelle: "Annuelle",
	neSaisPas: "Je ne sais pas",
	semestrielle: "Semestrielle",
};

const ESubPlacementPeuLiquiditeCorporationImplementation: Record<ESubPlacementPeuLiquiditeCorporation, string> = {
	actions: "Actions du CAC 40",
	livret_bancaire: "Livret bancaire",
	scpi: "SCPI",
};

const ESubscriptionTypeImplementation: Record<ESubscriptionType, string> = {
	legalPerson: "Personne morale",
	naturalPerson: "Personne physique",
	coSubscriptionNaturalPerson: "Personne physique en co-souscription",
};

const ESubDejaSouscritQuandCorporationImplementation: Record<ESubDejaSouscritQuandCorporation, string> = {
	less_than_a_year_ago: "Il y a moins d'un an",
	more_than_a_year_ago: "Il y a plus d'un an",
};

const ESubDejaSouscritCombienCorporationImplementation: Record<ESubDejaSouscritCombienCorporation, string> = {
	less_than_3: "Moins de 3",
	more_than_3: "Plus de 3",
};

const ESubDejaSouscritTypeCorporationImplementation: Record<ESubDejaSouscritTypeCorporation, string> = {
	autre: "Autre",
	logement: "Logement (borloo Robien, Scellier.....)",
	murs_commerce: "Murs de commerce",
};

const ESubDejaSouscritPartCorporationImplementation: Record<ESubDejaSouscritPartCorporation, string> = {
	between_10_25: "Entre 10 et 25%",
	between_5_10: "Entre 5 et 10%",
	greater_than_25: "Plus de 25%",
	less_than_5: "Moins de 5%",
};

const ESubFrequenceTransactionCorporationImplementation: Record<ESubFrequenceTransactionCorporation, string> = {
	annuelle: "Annuelle",
	annuelle_supereur: "> 1 an",
	mensuelle: "Mensuelle",
	trimestrielle: "Trimestrielle",
};

const ESubMoyenneTransactionCorporationImplementation: Record<ESubMoyenneTransactionCorporation, string> = {
	between_100k_300k: "Entre 100k et 300k",
	between_15k_50k: "Entre 15k et 50k",
	between_300k_500k: "Entre 300k et 500k",
	between_500k_1000k: "Entre 500k et 1000k",
	between_50k_100k: "Entre 50k et 100k",
	greater_1000k: "Plus de 1000k",
	less_15k: "Moins de 15k",
};

const ESubModeGestionUtiliseCorporationImplementation: Record<ESubModeGestionUtiliseCorporation, string> = {
	conseillee: "Gestion conseillée",
	direct: "Gestion directe",
	sous_mandat: "Gestion sous mandat",
};

const ESubNbOperationsSansConseillerCorporationImplementation: Record<ESubNbOperationsSansConseillerCorporation, string> = {
	more_than_ten: "Plus de 10",
	none: "Aucune",
	one_to_five: "De 1 à 5",
	six_to_ten: "De 6 à 10",
};

const ESubRisqueRendementCorporationImplementation: Record<ESubRisqueRendementCorporation, string> = {
	eleve: "Élevé - Une prise de risque importante (perte totale du capital)",
	faible: "Faible - Une prise de risque limitée (perte minime du capital)",
	moyen: "Moyen - Une prise de risque modérée (perte de capital acceptée)",
	null: "Nul - Aucune prise de risque ",
};

const ESubConnaissancesMarcheFinancierProduitsEpargneCorporationImplementation: Record<ESubConnaissancesMarcheFinancierProduitsEpargneCorporation, string> = {
	aucune: "Aucune",
	eleve: "Elevé (ayant une bonne connaissance et ayant une solide expérience professionnelle dans le secteur financier)",
	expert: "Expert (une connaissance de spécialiste des instruments financiers complexes)",
	faible: "Faible (débutant)",
	moyenne: "Moyenne (intermédiaire)",
};

const ESubCombienTempsRealisezInvestissementsCorporationImplementation: Record<ESubCombienTempsRealisezInvestissementsCorporation, string> = {
	between_10_15_ans: "Entre 10 et 15 ans",
	between_5_10_ans: "Entre 5 et 10 ans",
	greater_15_ans: "Plus de 15 ans",
	less_5_ans: "Moins de 5 ans",
};

const ESubReactionChuteBrusqueValeurInvestissementCorporationImplementation: Record<ESubReactionChuteBrusqueValeurInvestissementCorporation, string> = {
	conserver: "Vous les conservez dans une politique de long terme",
	investir: "Vous en profitez pour investir",
	vendre: "Vous les vendez afin de limiter vos pertes",
};

const ESubConnaissancesMarcheImmobilierCorporationImplementation: Record<ESubConnaissancesMarcheImmobilierCorporation, string> = {
	aucune: "Aucune",
	eleve: "Elevé (ayant une bonne connaissance des SCPI et ayant une solide expérience professionnelle dans le secteur financier ou étant accompagné et conseillé par des professionnels en investissement)",
	expert: "Expert (une connaissance de spécialiste des instruments financiers complexes comme, par exemple, des CFD (Contrat For Difference), et notamment des SCPI.",
	faible: "Faible (débutant: ayant une connaissance de base et pouvant prendre une décision d'investir, appuyée sur unedocumentation d'offre réglementée.)",
	moyenne:
		"Moyenne (intermédiaire: yant une connaissance moyenne des SCPI et pouvant prendre une décision d'investir,appuyée sur une documentation réglementée, avec la connaissance et la compréhension suffisante pour jauger des caractéristiques et des risques indiqués dans la documentation.)",
};

const EFundsOriginCorporationImplementation: Record<EFundsOriginCorporation, string> = {
	apport_compte_courant: "Apport en compte courant d'associé",
	cession_actif_immobiliers: "Cession d'actifs immobiliers par la personne morale",
	cession_actif_mobiliers: "Cession d'actifs de valeurs mobilières par la personne morale",
	emprunt: "Emprunt",
	epargne: "Epargne / Trésorerie",
	other: "Autre",
};

const ESubSupportPlacementCorporationImplementation: Record<ESubSupportPlacementCorporation, string> = {
	actionFondsActionsEtrangerer: "Actions/fonds action étranger",
	actionFondsActionsFrancais: "Action/fonds actions français",
	assuranceVie: "Assurance vie",
	compteTitresPEA: "Compte titres/PEA",
	epargneSalariale: "Epargne salariale",
	immobilierDirect: "Immobilier direct",
	liquidite: "Liquidité",
	monetaire: "Monétaire",
	obligations: "Obligations",
	privateEquity: "Private Equity",
	scpiOpci: "SCPI / OPCI",
	cac40: "CAC 40",
	euronextEurolist: "Euronext Eurolist",
	alternext: "Alternext",
};

const ESubSupportPlacementImplementation: Record<ESubSupportPlacement, string> = {
	actionFondsActionsEtrangerer: "Actions/fonds action étranger",
	actionFondsActionsFrancais: "Action/fonds actions français",
	assuranceVie: "Assurance vie",
	compteTitresPEA: "Compte titres/PEA",
	epargneSalariale: "Epargne salariale",
	immobilierDirect: "Immobilier direct",
	liquidite: "Liquidité",
	monetaire: "Monétaire",
	obligations: "Obligations",
	privateEquity: "Private Equity",
	scpiOpci: "SCPI / OPCI",
	cac40: "CAC 40",
	euronextEurolist: "Euronext Eurolist",
	alternext: "Alternext",
};

const ESubOriginePatrimoineCorporationImplementation: Record<ESubOriginePatrimoineCorporation, string> = {
	creation_valorisation_entreprise: "Création/valorisation d'une entreprise ou d'un fonds de commerce",
	heritage: "Héritage",
	salaire: "Salaire",
};

const EProductInformationImplementation: Record<EProductInformation, string> = {
	price: "Prix de part",
	category: "Catégorie",
	minNumberOfShares: "Minimum de parts (à la première souscription)",
	subFees: "Frais de souscription",
	dueDate: "Délai de jouissance",
	withdrawalPrice: "Prix de retrait",
	distributionRate: "Taux de distribution N-1",
	distributionRateN2: "Taux de distribution N-2",
	distributionFrequency: "Fréquence de distribution",
	capitalization: "Capitalisation",
	financialOccupancyRate: "Taux d'occupation financier",
	retainedEarnings: "Report à nouveau",
};

const EProductFileTypeImplementation: Record<EProductFileType, string> = {
	annual_report: "Rapport annuel",
	key_information: "Document d'information clé",
	plaquette: "Plaquette",
	rib: "RIB",
	status: "Statuts",
	trimestrial_report: "Rapport trimestriel",
	note_information: "Note d'information",
	other: "Autre",
};

const EProductDocumentTypeImplementation: Record<EProductDocumentType, string> = {
	accord_conjoint_versement: "Attestation accord du conjoint versement de dividendes",
	bulletin_souscription: "Bulletin de souscription",
	bulletin_souscription_plus_80_ans: "Bulletin de souscription pour les personnes de plus de 80 ans",
	mandat_sepa: "Mandat de prélèvement SEPA",
	appor_compte_courant_associe: "Attestation d'apport en compte courant d'associé",
	origine_fonds: "Attestation d'origine des fonds (DOF)",
	questionnaire_investisseur: "Questionnaire connaissance client / investisseur",
	remploi_fonds_propres: "Attestation de réemploi de fonds propres",
	forumulaire_facta: "Formulaire FATCA",
	rapport_special: "Rapport spécial",
	beneficiaires_effectifs: "Formulaire bénéficiaires effectifs",
	fiche_diligence: "Fiche de diligence",
};

const ESubDejaSouscritScpiPartPiervalImplementation: Record<ESubDejaSouscritScpiPartPierval, string> = {
	between_10_25: "Entre 10 et 25%",
	between_5_10: "Entre 5 et 10%",
	greater_than_25: "Plus de 25%",
	less_than_5: "Moins de 5%",
};

const ESubDejaSouscritScpiPartPiervalCorporationImplementation: Record<ESubDejaSouscritScpiPartPiervalCorporation, string> = {
	between_10_25: "Entre 10 et 25%",
	between_5_10: "Entre 5 et 10%",
	greater_than_25: "Plus de 25%",
	less_than_5: "Moins de 5%",
};

const EFundsOriginCommunityInvestmentImplementation: Record<EFundsOriginCommunityInvestment, string> = {
	donation: "Donation",
	goods_before_marriage: "Biens acquis avant le mariage",
	succession: "Succession",
	other: "Autre",
};

const trads = {
	common: {
		continue: "Continuer",
		cancel: "Annuler",
		logout: "Déconnexion",
		consults: "Consulter",
		search: "Rechercher",
		yes: "Oui",
		no: "Non",
		save: "Enregistrer",
		back: "Retour",
		delete: "Supprimer",
		add: "Ajouter",
		next: "Suivant",
		next_page: "Passer à l'étape suivante",
		back_to: "Reprendre",
		close: "Fermer",
		other: "Autre",
		souscripteur1: "Souscripteur 1",
		souscripteur2: "Souscripteur 2",
		civility_cosubscription: "Conjoints",
		results: "Résultats",
		warning: "Attention",
		see_detail: "Voir le détail",
		no_content: "Pas de contenu pour le moment",
		loading: "Chargement...",
		find_your_address: "Trouvez votre adresse",
		back_to_subscriptions: "Retour aux souscriptions",
		back_to_clients: "Retour aux clients",
		end: "Fin",
		step: "Étape",
		modify: "Modifier",
		not_valid_client: "Ce client contient des informations invalides ou incomplètes pour entamer une souscription. \n\n Sélectionnez-le pour le compléter",
		modify_informations: "Modifier les informations",
		save_informations: "Enregistrer les informations",
		actif: "Actif",
		inactif: "Inactif",
		invite: "Inviter",
		invited: "Invité",
		send: "Envoyer",
		resend_in_5_minutes: "Le mail d'invitation a été envoyé.\n Vous pourrez renvoyer un mail dans 5 minutes",
	},
	enums: {
		EPaymentMethod: EPaymentMethodImplementation,
		ESubSituationPatrimoniale: ESubSituationPatrimonialeImplementation,
		ESubTrancheMarginaleImposition: ESubTrancheMarginaleImpositionImplementation,
		ESubInvestissementPatrimoineGlobal: ESubInvestissementPatrimoineGlobalImplementation,
		ESubPartInvestissementPatrimoineGlobalCorporation: ESubPartInvestissementPatrimoineGlobalCorporationImplementation,
		ESubChargeAnnuelleFoyerFiscal: ESubChargeAnnuelleFoyerFiscalImplementation,
		EFinancialObjective: EFinancialObjectiveImplementation,
		EFundsOrigin: EFundsOriginImplementation,
		ESubModeGestionUtilise: ESubModeGestionUtiliseImplementation,
		ESubRisqueRendementAccepter: ESubRisqueRendementAccepterImplementation,
		ESubScpiOpciNombreSouscriptions: ESubScpiOpciNombreSouscriptionsImplementation,
		ESubScpiOpciFrequenceTransaction: ESubScpiOpciFrequenceTransactionImplementation,
		ESubScpiOpciMoyenneTransactionCout: ESubScpiOpciMoyenneTransactionCoutImplementation,
		ESubConnaissancesMarcheFinancierProduitsEpargne: ESubConnaissancesMarcheFinancierProduitsEpargneImplementation,
		ESubConnaissancesMarcheFinancierProduitsEpargneTooltip: ESubConnaissancesMarcheFinancierProduitsEpargneTooltipImplementation,
		ESubCombienTempsRealisezInvestissements: ESubCombienTempsRealisezInvestissementsImplementation,
		ESubConnaissancesMarcheImmobilier: ESubConnaissancesMarcheImmobilierImplementation,
		ESubConnaissancesMarcheImmobilierTooltip: ESubConnaissancesMarcheImmobilierTooltipImplementation,
		ESubReactionChuteBrusqueValeurInvestissement: ESubReactionChuteBrusqueValeurInvestissementImplementation,
		ECivility: ECivilityImplementation,
		EMaritalStatus: EMaritalStatusImplementation,
		ELegalCapacity: ELegalCapacityImplementation,
		EMatrimonialRegime: EMatrimonialRegimeImplementation,
		ESubHorizonInvestissement: ESubHorizonInvestissementImplementation,
		ESubHorizonInvestissementCorporation: ESubHorizonInvestissementCorporationImplementation,
		ESubPartInvestissementsDurables: ESubPartInvestissementsDurablesImplementation,
		ESubPartInvestissementsDurablesCorporation: ESubPartInvestissementsDurablesCorporationImplementation,
		ESubPreferenceDurabilite: ESubPreferenceDurabiliteImplementation,
		ESubPreferenceDurabiliteCorporation: ESubPreferenceDurabiliteCorporationImplementation,
		ESubPreferenceDurabiliteTooltip: ESubPreferenceDurabiliteTooltipImplementation,
		ESubPreferenceDurabiliteCorporationTooltip: ESubPreferenceDurabiliteCorporationTooltipImplementation,
		ESubModeEntreeRelation: ESubModeEntreeRelationImplementation,
		ESubModeEntreeRelationCorporation: ESubModeEntreeRelationCorporationImplementation,
		ESubStepMO: ESubStepMOImplementation,
		ESubStepCGP: ESubStepCGPImplementation,
		ECountry: ECountryImplementation,
		ENationality: ENationalityImplementation,
		EValidationErrors: EValidationErrorImplementation,
		ETypeConseiller: ETypeConseillerImplementation,
		EHabilitation: EHabilitationImplementation,
		ELigneMetier: ELigneMetierImplementation,
		EEtatCompte: EEtatCompteImplementation,
		EFormeJuridique: EFormeJuridiqueImplementation,
		EAssociationProfesionnelle: EAssociationProfesionnelleImplementation,
		ESubCommentConnuConsultim: ESubCommentConnuConsultimImplementation,
		ESubCommentConnuConsultimCorporation: ESubCommentConnuConsultimCorporationImplementation,
		ESubNbOperationsDerniersMois: ESubNbOperationsDerniersMoisImplementation,
		ESubPerformanceScpiFonction: ESubPerformanceScpiFonctionImplementation,
		ESubPerformanceScpiFonctionCorporation: ESubPerformanceScpiFonctionCorporationImplementation,
		ESubScpiRecourtCredit: ESubScpiRecourtCreditImplementation,
		ESubScpiRecourtCreditCorporation: ESubScpiRecourtCreditCorporationImplementation,
		ESubScpiImpacteesPar: ESubScpiImpacteesParImplementation,
		ESubScpiImpacteesParCorporation: ESubScpiImpacteesParImplementationCorporation,
		ESubFiscaliteApplicableScpi: ESubFiscaliteApplicableScpiImplementation,
		ESubFiscaliteApplicableScpiCorporation: ESubFiscaliteApplicableScpiImplementationCorporation,
		EProductCategories: EProductCategoriesImplementation,
		ETestAdequationGroups: ETestAdequationGroupsImplementation,
		ETestAdequationCorporationGroups: ETestAdequationCorporationGroupsImplementation,
		EPatrimoineRevenusGroups: EPatrimoineRevenusGroupsImplementation,
		EPatrimoineRevenusCorporationGroups: EPatrimoineRevenusCorporationGroupsImplementation,
		EFinanceDurableGroups: EFinanceDurableGroupsImplementation,
		EFinanceDurableCorporationGroups: EFinanceDurableCorporationGroupsImplementation,
		EConnaissanceExperienceGroups: EConnaissanceExperienceGroupsImplementation,
		EConnaissanceExperienceCorporationGroups: EConnaissanceExperienceCorporationGroupsImplementation,
		EAutreCgpGroups: EAutreCgpGroupsImplementation,
		EAutreCgpCorporationGroups: EAutreCgpCorporationGroupsImplementation,
		ESubFilesTypes: ESubFilesTypesImplementation,
		EOtherErrors: EOtherErrorsImplementation,
		EDistributionFrequency: EDistributionFrequencyImplementation,
		ECommunityInvestment: ECommunityInvestmentImplementation,
		ECommunityInvestmentTooltip: ECommunityInvestmentTooltipImplementation,
		ELegalStatus: ELegalStatusImplementation,
		ECorporationSteps: ECorporationStepsImplementation,
		ECorporationRegimeFiscal: ECorporationRegimeFiscalImplementation,
		ECorporationResidenceFiscale: ECorporationResidenceFiscaleImplementation,
		ECorporationFormeJuridique: ECorporationFormeJuridiqueImplementation,
		EQualiteRepresentant: EQualiteRepresentantImplementation,
		ECategorieSocialProfessionnel: ECategorieSocialProfessionnelImplementation,
		ESubCategorisationMifCorporation: ESubCategorisationMifCorporationImplementation,
		ESubStatutFactaCrsCorporation: ESubStatutFactaCrsCorporationImplementation,
		ESubPeriodicitePiervalCorporation: ESubPeriodicitePiervalCorporationImplementation,
		ESubPlacementPeuLiquiditeCorporation: ESubPlacementPeuLiquiditeCorporationImplementation,
		ESubscriptionType: ESubscriptionTypeImplementation,
		ESubDejaSouscritQuandCorporation: ESubDejaSouscritQuandCorporationImplementation,
		ESubDejaSouscritCombienCorporation: ESubDejaSouscritCombienCorporationImplementation,
		ESubDejaSouscritTypeCorporation: ESubDejaSouscritTypeCorporationImplementation,
		ESubDejaSouscritPartCorporation: ESubDejaSouscritPartCorporationImplementation,
		ESubFrequenceTransactionCorporation: ESubFrequenceTransactionCorporationImplementation,
		ESubMoyenneTransactionCorporation: ESubMoyenneTransactionCorporationImplementation,
		ESubModeGestionUtiliseCorporation: ESubModeGestionUtiliseCorporationImplementation,
		ESubNbOperationsSansConseillerCorporation: ESubNbOperationsSansConseillerCorporationImplementation,
		ESubRisqueRendementCorporation: ESubRisqueRendementCorporationImplementation,
		ESubConnaissancesMarcheFinancierProduitsEpargneCorporation: ESubConnaissancesMarcheFinancierProduitsEpargneCorporationImplementation,
		ESubCombienTempsRealisezInvestissementsCorporation: ESubCombienTempsRealisezInvestissementsCorporationImplementation,
		ESubReactionChuteBrusqueValeurInvestissementCorporation: ESubReactionChuteBrusqueValeurInvestissementCorporationImplementation,
		ESubConnaissancesMarcheImmobilierCorporation: ESubConnaissancesMarcheImmobilierCorporationImplementation,
		EFundsOriginCorporation: EFundsOriginCorporationImplementation,
		ESubSupportPlacementCorporation: ESubSupportPlacementCorporationImplementation,
		ESubSupportPlacement: ESubSupportPlacementImplementation,
		ESubOriginePatrimoineCorporation: ESubOriginePatrimoineCorporationImplementation,
		EProductInformation: EProductInformationImplementation,
		EProductFileType: EProductFileTypeImplementation,
		EProductDocumentType: EProductDocumentTypeImplementation,
		ESubDejaSouscritScpiPartPierval: ESubDejaSouscritScpiPartPiervalImplementation,
		ESubDejaSouscritScpiPartPiervalCorporation: ESubDejaSouscritScpiPartPiervalCorporationImplementation,
		EFundsOriginCommunityInvestment: EFundsOriginCommunityInvestmentImplementation,
	},
	pages: {
		home: {
			page_title: "Accueil",
		},
		not_found: {
			subtitle: "Oups ! La page que vous cherchez semble introuvable.",
			button: "Retour à l'accueil",
		},
		client: {
			page_title: "Client",
			sub_pages: {
				legal_person_clients: {
					page_title: "Clients personnes morales",
				},
				physical_person_clients: {
					page_title: "Clients personnes physiques",
				},
				personal_information: {
					page_title: "Informations personnelles",
					modify_information: "Modifier les informations",
					see_spouse: "Voir la fiche du conjoint",
				},
				validated_subscriptions: {
					page_title: "Souscriptions validées",
					table: {
						columns: {
							name: "Nom du produit",
							validation_date: "Date de souscription",
							due_date: "Date de jouissance",
							payout_date: "Date de versement des premiers loyers",
							share_number: "Nombre de parts",
							amount_subscribed: "Montant souscrit (€)",
							estimation_amount: "Valorisation estimée*",
						},
					},
				},
				customer_insight: {
					page_title: "Connaissance client",
					no_data:
						"Il semble qu'aucune question de cette catégorie n'ait été complétée pour ce client.\n\nLes réponses que vous fournirez lors des souscriptions seront enregistrées ici. Elles permettront de vous faire gagner du temps en pré-remplissant les futures souscriptions de ce client.",
					sub_pages: {
						payment_method: {
							page_title: "Règlement",
						},
						patrimoine_revenus: {
							page_title: "Patrimoine et revenus",
						},
						finance_durable: {
							page_title: "Finance durable",
						},
						connaissance_experience: {
							page_title: "Connaissance et expérience",
						},
						test_adequation: {
							page_title: "Test d'adéquation",
						},
					},
				},
			},
		},
		corporations: {
			table: {
				columns: {
					raisonSociale: "Raison sociale",
					formeJuridique: "Forme juridique",
					montantTotalSouscriptions: "Montant total de souscriptions (€)",
					dateLastSubscription: "Date de la dernière souscription",
					nbProduitsSouscrits: "Nombre de produits souscrits",
				},
			},
		},
		clients: {
			page_title: "Vos clients",
			table: {
				columns: {
					civility: "Civilité",
					last_name: "Nom",
					first_name: "Prénom",
					subscription_sum: "Montant total des souscriptions",
					last_subscription_date: "Date de la dernière souscription",
					number_of_subscriptions: "Nombre de produits souscrits",
				},
			},
		},
		mo_upload_documents: {
			page_title: "Dépôt des documents",
			input_name_label: "Nom du document",
			cta: "Ajouter le document",
			modal: {
				title: "Êtes-vous sûr ?",
				text: "Cette action est irréversible.",
				cta: "Supprimer",
			},
		},
		subscription_information: {
			page_title: "Souscription {{productName}} de {{civility}} {{subscriberName}}",
			page_title_corporation: "Souscription {{productName}} de {{corporationName}}",
			title: "Informations de la souscription",
			validation_date: "Date de validation",
			due_date: "Date de jouissance",
			payout_date: "Date de versement des premiers loyers",
			share_number: "Nombre de parts",
			amount_subscribed: "Montant souscrit",
			estimation_amount: "Valorisation estimée",
			attestation: "Attestation de part",
			signed: "Souscription signée par le client",
			estimation_amount_info:
				"Valorisation estimée (valeur de retrait) en fonction du nombre de parts possédé par le client à ce jour à notre connaissance, sous réserve de vérification auprès de la société de gestion",
		},
		subscription_summary: {
			page_title: "Souscription {{productName}} de {{civility}} {{subscriberName}}",
			sub_pages: {
				subscribers: {
					page_title: "Souscripteur(s)",
					corporationInfo: "Informations société",
					legal_representative: "Représentant légal",
					beneficiary: "Bénéficiaire effectif",
				},
				payment: {
					page_title: "Mode de règlement",
				},
				bulletin: {
					page_title: "Bulletin de souscription",
				},
				files: {
					page_title: "Pièces justificatives",
				},
			},
		},
		subscriptions: {
			page_title: "Souscriptions",
			dropdown_button_text: "Lancer une nouvelle souscription",
			back_to_subscriptions: "Retour aux souscriptions",
			table: {
				find_subscrition: "Chercher une souscription",
				civility: "Civilité",
				lastname: "Nom",
				name: "Prénom",
				product_name: "Nom du produit",
				amount_subscribed: "Montant souscrit",
				payment_method: "Mode de réglement",
			},
			unsaved_modifications: "Les modifications apportées n'ont pas été enregistrées.",
			continue_unsave: "Continuer sans enregistrer",
			analyse_processing: "Analyse en cours",
			sub_pages: {
				in_progress: {
					page_title: "En cours (non envoyé à wenimmo)",
					civility: "Civilité",
					lastname: "Nom",
					name: "Prénom",
					subscriber: "Souscripteur",
					number_of_products: "Nombre de produits",
					amount_subscribed: "Montant souscrit",
					type: "Type",
					modal: {
						title: "Êtes-vous sûr ?",
						irreversible: "Cette action est irréversible.",
					},
				},
				waiting_signature: {
					page_title: "En attente de signature",
					date: "Date d'envoi pour signature au client",
				},
				waiting_validation: {
					page_title: "En attente de validation société de gestion",
					date: "Date d'envoi à la société de gestion",
				},
				validated: {
					page_title: "Validées",
					date: "Date de validation",
				},
			},
			existing_clients: {
				page_title: "Clients existants",
				title: "Sélectionner le client",
				launch_co_subscription: "Lancer une co-souscription",
				launch_subscription: "Lancer une souscription seul",
				modal_no_co_suscriber: {
					title: "Conjoint introuvable",
					text: "Le client sélectionné n'a pas encore de conjoint",
					cta: "Créer la fiche du conjoint",
				},
				modal_not_valid_client: {
					title: "Attention",
					spouse_title: "Attention, conjoint non valide",
					text: "Veuillez compléter l'ensemble des informations afin de pouvoir souscrire pour ce client.",
				},
			},
			existing_corporation: {
				page_title: "Selection d'une personne morale",
				title: "Sélectionner le client",
				subtitle: "Sélectionnez dans la liste ci-dessous la société concernée par cette souscription.",
				launch_co_subscription: "Lancer une co-souscription",
				launch_subscription: "Lancer une souscription",
				modal_no_co_suscriber: {
					title: "Conjoint introuvable",
					text: "Le client sélectionné n'a pas encore de conjoint",
					cta: "Créer la fiche du conjoint",
				},
			},
			upload_documents: {
				page_title: "Dépôt des documents",
				finalize: "Finaliser la souscription",
				description:
					"Pour terminer votre parcours, merci de bien vouloir télécharger l'ensemble des justificatifs nécessaires à la validation de votre dossier auprès des sociétés de gestion.",
				checkbox_text:
					"Dans le cadre du document d'entrée en relation signé par mon client, celui-ci accepte de transmettre ses données personnelles pour le traitement de son dossier.",
				documents_needed: "Faites attention a bien fournir tous les documents demandés.",
				modal: {
					title: "Souscription finalisée",
					description:
						"Le dossier a été transmis au back office de Wenimmo.\n\nAprès étude du dossier par nos services, vous serez notifié de l'envoi du dossier en signature électronique à votre client.\n\nVous avez également la possibilité de nous contacter :\n",
					address: "backoffice@wenimmo.com",
				},
				uploaders: {
					cartId: "Pièce d'identité (en cours de validité)*",
					rib: "RIB de perception des dividendes*",
					rib_prelevement: "RIB de prélèvement (si différent du RIB dividendes)",
					domicileProof: "Justificatif de Domicile (moins de 3 mois)*",
					fundsOrigin: "Justificatif(s) d'Origine des Fonds*",
					others: "Autres documents (traçabilité des fonds, attestation, ...)",
					spouseCartId: "Pièce d'identité du conjoint*",
					rib_corporation: "RIB de perception des dividendes*",
					rib_prelevement_corporation: "RIB de prélèvement (si différent du RIB dividendes)",
					kbis: "Kbis infogreffe (de moins de 3 mois) *",
					certified_status: "Statuts certifiés conformes (de moins de 3 mois) * ",
					card_id_legal_representative: "Carte d'identité du représentant légal*",
					justificatif_domicile_legal_representative: "Justificatif de domicile du représentant légal*",
					beneficiaries_list: "Liste des bénéficiaires effectifs*",
					justificatif_origine_fonds_corporation: "Justificatif de l'origine des fonds*",
					beneficiaries_id: "Pièce d'identité des bénéficiaires effectifs*",
					beneficiaries_proof_address: "Justificatif de domicile des bénéficiaires effectifs*",
					liasse_fiscale: "Liasse fiscale ou formulaire 2072 (le cas échéant)",
				},
			},
			create_client: {
				page_title: "Création nouveau client",
				title: "Création nouveau client",
				back: "Retour aux souscriptions",
			},
			create_corporation: {
				page_title: "Personne morale",
				title: "Création personne morale",
				back: "Retour aux souscriptions",
				beneficiaries: {
					is_legal_representative_beneficiary: "Le représentant légal est-il un bénéficiaire effectif ?",
					beneficiary_is: "Le bénéficiaire effectif est un",
					add_beneficiary: "Ajouter un bénéficiaire",
					effectiv_beneficiary: "Bénéficiaire effectif",
					beneficiary: "Bénéficiaire",
					new_client: "Nouveau client",
					existing_client: "Client existant",
					edit_beneficiaries: "Edition du bénéficiaire",
				},
				legal_representative: {
					legal_representative_is: "Le représentant légal est un",
					message_signataire: "Fournir le pouvoir du signataire le cas échéant",
					legal_representative: "Représentant légal",
					edit_legal_representative: "Edition du représentant légal",
				},
			},
			summary: {
				page_title: "Récapitulatif",
				page_title_corporation: "Récapitulatif",
				title: "Récapitulatif",
			},
			select_products: {
				page_title: "Sélection des produits",
				title: "Sélection des produits",
				subtitle: "Sélectionnez les produits auxquels votre client souhaite souscrire et ajoutez-les au panier.",
				next_page: "Passer à l'étape suivante",
				total_amount: "Montant total de la souscription",
				table: {
					columns: {
						scpi: "SCPI disponibles",
						paiement_method: "Mode de réglement",
						quantity: "Nombre de parts",
						amount: "Nombre de parts",
						calculated_price: "Montant de la souscription",
						start_price: "Prix de part",
					},
					placeholders: {
						select_scpi: "Sélectionner une SCPI",
						select_paiement_method: "Sélectionner un mode de réglement",
					},
				},
				modal: {
					text: `Il est nécessaire d'acquérir un minimum de {{minimumShare}} parts lors de la première souscription à ce produit.Avant de procéder à la souscription, veuillez vérifier que votre client détient déjà des parts de cette SCPI.`,
					button: "J'ai compris",
				},
			},
			payment_methods: {
				page_title_corporation: "Règlement",
				page_title: "Réglement",
				title: "Règlement",
				description: "Spécifiez les modalités de règlement des souscriptions de votre client.",
				prelevement_info:
					"Si co-souscription : Règlement d'un compte joint recommandé. Si souscription seule : Règlement d'un compte au nom seul du souscripteur recommandé.",
			},
			patrimoine_revenus: {
				page_title: "Patrimoine et revenus",
				page_title_corporation: "Patrimoine et revenus",
				title: "Patrimoine et revenus",
				questions: {
					dontEnfantsACharge: "Dont enfants à charge",
					situationFinanciereCorporation: {
						capitauxPropre: "Capitaux propres",
						totalBilan: "Total du bilan",
						resultatNet: "Résultat net",
						capitalSocial: "Capital social",
						chiffreDAffaire: "Chiffre d'affaire",
					},
					compositionPatrimoine: {
						immobilierUsage: "Immobilier d'usage",
						immobilierLocatif: "Immobilier locatif",
						actifsFinanciers: "Autres actifs financiers",
						assuranceVie: "Assurance vie",
						liquidites: "Liquidités",
						actifsProfessionnels: "Actifs professionnels",
						epargneRetraite: "Epargne retraite",
						scpi: "SCPI",
						actions: "Actions",
						obligations: "Obligations",
						autre: "Autre",
					},
					compositionPatrimoineCorporation: {
						patrimoineImmobilier: "Patrimoine Immobilier",
						patrimoineFinancier: "Patrimoine financier",
						titresScpiOpci: "dont titres SCPI/OPCI",
						liquiditesTresorerie: "Liquidités/Trésorerie",
					},
					originePatrimoine: {
						salaire: "Salaire",
						valorisation: "Création/valorisation d'une entreprise ou d'un fonds de commerce",
						heritage: "Héritage",
					},
					originePatrimoineCorporation: {
						salaire: "Salaire",
						creation_valorisation_entreprise: "Création/valorisation d'une entreprise ou d'un fonds de commerce",
						heritage: "Héritage",
					},
					compositionRevenus: {
						immobiliers: "Revenus immobiliers",
						salaires: "Salaires",
						retraites: "Retraites",
						financiers: "Revenus financiers",
						mobiliers: "Revenus mobiliers",
						autres: "Autre",
					},
					compositionRevenusCorporation: {
						revenusImmobiliers: "Revenus immobiliers",
						activite: "Activité",
						retraites: "Retraites",
						revenusFinanciers: "Revenus financiers",
						autre: "Autre",
					},
					revenuCharge: {
						nature: "Nature",
						montant: "Montant",
					},
				},
			},
			files: {
				page_title: "Documents",
				page_title_corporation: "Documents",
				title: "Documents",
			},
			connaissance_experience: {
				page_title: "Connaissance & expérience",
				page_title_corporation: "Connaissance & expérience représentant légal",
				title: "Connaissance & expérience",
				supportPlacement: {
					queVousConnaissez: "Que vous connaissez",
					dontVousDisposez: "Dont vous disposez",
					nombreSouscriptions: "Nombre de souscriptions au cours des 12 derniers mois",
					frequenceTransaction: "Fréquence moyenne des transactions",
					moyenneTransaction: "Moyenne de transaction en €",
					scpiOpciNombreSouscriptions: "Nombre de souscriptions de parts de SCPI",
				},
			},
			autre_cgp: {
				page_title: "Appréciation sur l'opération",
				page_title_corporation: "Appréciation sur l'opération",
				title: "Appréciation sur l'opération",
			},
			test_adequation: {
				page_title: "Test d'adéquation",
				page_title_corporation: "Test d'adéquation",
				title: "Test d'adéquation",
			},
			finance_durable: {
				page_title: "Finance durable",
				page_title_corporation: "Finance durable",
				title: "Finance durable",
			},
			new_subscription_modal: {
				title: "Lancer une souscription",
				new_client: "Nouveau client",
				existing_client: "Client existant",
			},
		},
		products: {
			page_title: "Produits",
			completed: {
				label: "Complet",
			},
			not_available: {
				label: "Pas de mapping",
			},
			incompleted: {
				label: "Incomplet",
				tooltip: "Produit non digitalisable : Uploader les documents de souscription pour ce produit.",
			},
			table: {
				columns: {
					status: "Statut",
					product: "Produit",
					distribution: "Taux de distribution N-1",
					distribution_n2: "Taux de distribution N-2",
					distribution_frequency: "Fréquence de distribution",
					capitalization: "Capitalisation",
					occupation: "Taux d'occupation financier",
					report: "Report à nouveau (jours)",
					category: "Catégorie",
				},
			},
			history_table: {
				columns: {
					data: "Donnée",
					modificationDate: "Date de modification",
					creator: "Responsable",
				},
			},
		},
		product: {
			page_title: "Produit",
			own_by: "Société de gestion",
			new_subscription: "Lancer une nouvelle souscription",
			subscription_documents: "Documents de souscription",
			back_to_products: "Retour aux produits",
			modify_data: "Modifier les données",
			modify_name: "Modifier le nom",
			add_document: "Ajouter un document",
			add_the_document: "Ajouter le document",
			deposit_document: "Déposer un document",
			no_document: "Aucun document",
			download_all_documents: "Télécharger tous les documents",
			trimestrial_report_history: "Historique des bulletins trimestriels",
			rename: "Renommer",
			delete_document: "Supprimer le document",
			irreverible_action: "Cette action est irréversible.",
			type: "Type",
			document_name: "Nom du document",
			data_history: "Historique des données",
			download: "Télécharger",
			documents_table: {
				name: "Nom",
				updated_at: "Date de mise à jour",
			},
			informations: {
				title: "Information de souscription",
				price: "Prix de part",
				min_number_of_shares: "Minimum de parts (à la première souscription)",
				share: "part",
				shares: "parts",
				due_date: "Délai de jouissance",
				due_date_message: "{{dueDayVar}} jour du {{dueMonthVar}} mois suivant la date de souscription",
				day: "jour",
				days: "jours",
				months: "mois",
				withdrawal_price: "Prix de retrait",
				distribution_rate: "Taux de distribution N-1",
				distribution_rate_n2: "Taux de distribution N-2",
				distribution_frequency: "Fréquence de distribution",
				financial_occupancy_rate: "Taux d'occupation financier",
				capitalization: "Capitalisation",
				retained_earnings: "Report à nouveau (jours)",
				sub_fees: "Frais de souscription",
				category: "Catégorie",
			},
			signable_documents: {
				missing_template: {
					label: "Document manquant",
					tooltip: "Produit non digitalisé : il n'est pas encore possible de générer les documents de souscription pour ce produit.",
				},
			},
		},
		document_template_subscription: {
			page_title: "{{productName}} - Documents de souscription",
			title: "{{productName}} - Documents de souscription",
			sub_pages: {
				corporation: {
					label: "Documents personne morale",
				},
				physical_person: {
					label: "Documents personne physique",
				},
			},
		},
		account: {
			page_title: "Mon compte",
			my_informations: "Mes informations",
			password: "Mot de passe",
			my_signature: "Ma signature manuscrite",
			create_signature: "Créer une signature",
			modify_signature: "Modifier la signature",
			modify_password: "Modifier le mot de passe",
			change_password: "Changer de mot de passe",
			signature_info:
				"Votre signature manuscrite est requise pour réaliser des souscriptions. Elle sera utilisée sur les documents envoyés aux sociétés de gestion, sans que vous ayez à signer électroniquement.",
			password_requirements: {
				title: "Le mot de passe doit au moins contenir:",
				min_length: "12 caractères",
				lowercase: "Une minuscule",
				uppercase: "Une majuscule",
				number: "Un chiffre",
				special_character: "Un caractère spécial",
			},
			current_password: {
				label: "Mot de passe actuel",
				placeholder: "Entrer le mot de passe",
			},
			new_password: {
				label: "Nouveau mot de passe",
				placeholder: "Entrer le nouveau mot de passe",
			},
			confirm_new_password: {
				label: "Confirmer le mot de passe",
				placeholder: "Confirmer le mot de passe",
			},
		},
		login: {
			welcome: {
				page_title: "Bienvenue",
				title: "Bienvenue sur Wenimmo",
				description: "Configurez votre mot de passe afin d'acceder a la plateforme",
				placeholder: "Entrer l'e-mail",
				button: "Se connecter",
			},
			connect: {
				page_title: "Connection",
				title: "Bienvenue sur Wenimmo",
				description: "Configurez votre mot de passe afin d'acceder a la plateforme",
				label: "Mot de passe",
				placeholder: "Entrer le mot de passe",
				forgot_password: "Mot de passe oublié ?",
				button: "Se connecter",
			},
			request_pin_code: {
				label: "Code pin à 6 chiffres",
				send_pin_code: "Envoyer le code par mail",
				resend_pin_code_in: "Renvoyer un mail dans: ",
				initialize_password: {
					page_title: "Creation de compte",
					title: "Créer votre mot de passe",
					description:
						"Un code de sécurité à 6 chiffres a été envoyé sur votre boite mail afin de vérifier que vous en êtes bien le propriétaire.\n\nVeuillez renseigner ce code ci-dessous (vérifiez vos spams).",
				},
				forgotten_password: {
					page_title: "Mot de passe oublié",
					title: "Mot de passe oublié ?",
					description: "Afin de réinitialiser votre mot de passe, vous devez entrer un code pin à 6 chiffres envoyé dans votre boite mail.",
				},
			},
			reset_password: {
				create_password: "Créer un mot de passe",
				confirm_password: "Confirmer le mot de passe",
				enter_password: "Entrer votre mot de passe",
				requirements: "Au moins 12 caractères, une minuscule, une majuscule, un chiffre, un caractère spécial",
				initialize_password: {
					page_title: "Creation de compte",
					title: "Initialiser votre mot de passe",
					description: "Créer votre mot de passe",
					success_button: "S'enregister",
				},
				forgotten_password: {
					page_title: "Mot de passe oublié",
					title: "Réinitialiser le mot de passe",
					description: "Créer votre nouveau mot de passe",
					success_button: "Réinitialiser le mot de passe",
				},
			},
		},
		mo_subscriptions: {
			page_title: "Souscriptions",
			title: "Espace souscription",
			sub_pages: {
				draft: {
					page_title: "Brouillons",
					date: "Date de création",
				},
				table: {
					find_subscription: "Chercher une souscription",
					full_name: "Souscripteur",
					cabinet_name: "Nom du cabinet (CGP)",
					cgp_email: "Email du CGP",
					cgp_phone: "Téléphone du CGP",
					payment_method: "Méthode de réglement",
					scpi_name: "Nom de la SCPI",
					amount: "Montant",
					number_of_shares: "Nombre de parts",
					status: "Statut",
					type: "Type",
				},
				to_process: {
					page_title: "À traiter",
					date: "Date de réception",
				},
				waiting_signature: {
					page_title: "En attente de signature",
					date: "Date d'envoi pour signature au client",
				},
				waiting_validation: {
					page_title: "En attente de validation",
					date: "Date d'envoi SDG",
				},
				validated: {
					page_title: "Validées",
					date: "Date de validation",
				},
				modal: {
					title: "Selectionnez une date",
					description: "Veuillez indiquer la date de validation de la souscription",
				},
			},
		},
		mo_subscription_summary: {
			page_title: "Souscriptions informations",
			title: "Souscriptions",
			cgp: "CGP :",
			email_cgp: "Email :",
			office: "Cabinet",
			document_management: "Gestion des documents",
			scpi: "SCPI :",
			nbParts: "Nb parts :",
			cabinet: "Cabinet :",
			subscribed_amount: "Montant souscrit :",
			reception_date: "Date de réception :",
			validation_date: "Date de validation :",
			due_date: "Date de jouissance :",
			payout_date: "Date de versement des premiers loyers :",
			sub_pages: {
				subscribers: {
					page_title: "Souscripteur(s)",
				},
				payment: {
					page_title: "Mode de règlement",
				},
				bulletin: {
					page_title: "Bulletin de souscription",
				},
				files: {
					page_title: "Pièces justificatives",
				},
				signable_documents: {
					page_title: "Documents de souscription",
				},
			},
		},
		partners: {
			tabs: {
				users: "Conseillers",
				offices: "Cabinets",
			},
			page_title: "Partenaires",
			title: "Partenaires",
			dopdown_button: "Créer un partenaire",
			table: {
				columns: {
					name: "Nom",
					first_name: "Prénom",
					cabinet: "Cabinet",
					id: "Identifiant",
					raison_social: "Cabinets",
					creation_date: "Date de création",
					number_of_subscriptions: "Nombre de souscriptions",
					last_login_at: "Date de dernière connexion",
				},
			},
			pages: {
				offices: {
					tabs: {
						offices: "Données cabinet",
						users: "Conseillers",
					},
				},
				offices_details: {
					buttons: {
						edit: "Modifier les informations",
						save: "Sauvegarder",
					},
					identite_juridique: {
						title: "Identité juridique",
						company_name: "Raison sociale*",
						forme_juridique: "Forme juridique*",
						siret: "Numéro d'identification de l'entreprise (SIRET, RIDET, T.A.H.I.T.I.)*",
						rcs: "RCS*",
						capital_social: "Capital social*",
					},
					habilitations: {
						title: "Habilitations & autorisations",
						type_conseiller: "Type du conseiller*",
						habilitations: "Habilitations*",
						association_professionnelle: "Association professionnelle",
						numero_orias: "N°Orias",
						ligne_metier: "Ligne de metier",
					},
					gerant: {
						title: "Données du gérant",
						nom: "Nom*",
						prenom: "Prénom*",
						date_naissance: "Date de naissance*",
						email_gerant: "Email du gérant*",
					},
					coordonnes_cabinet: {
						title: "Coordonnées du cabinet",
						email_administratif: "Email administratif",
						numero_telephone: "N° de téléphone fixe",
						adresse: "Adresse (n° + rue + ville + code postal)*",
					},
					donnees_bancaires: {
						title: "Données bancaires (commission)",
						titulaire_compte: "Titulaire du compte*",
						iban: "IBAN*",
						bic: "BIC*",
					},
					produits_cabinet: {
						title: "Les produits auxquels le cabinet a accès",
						check_all_spci: "Cocher toutes les SCPI",
					},
				},
				user_details: {
					send_reminder_email_to: "Envoyer un email d'invitation à:",
					form: {
						last_name: {
							label: "Nom",
							placeholder: "Nom",
						},
						first_name: {
							label: "Prénom",
							placeholder: "Prénom",
						},
						birth_date: {
							label: "Date de naissance",
						},
						email: {
							label: "Email principal (adresse email de connexion)",
							placeholder: "Indiquer l'email",
						},
						phone_number: {
							label: "N° de téléphone mobile",
							placeholder: "N° de téléphone mobile",
						},
						etat_compte: {
							label: "Etat du compte",
							placeholder: "Etat du compte",
						},
						signature_date: {
							label: "Date de signature",
						},
						creation_date: {
							label: "Date de création",
						},
					},
				},
			},
		},
		create_partner: {
			page_title: "Create partner",
			sub_pages: {
				create_office: {
					title: "Création / Sélection d'un cabinet",
					find_office: "Chercher un cabinet",
					existing_office: "Cabinet existant",
					new_office: "Nouveau Cabinet",
					legal_identity: "Identité juridique",
					authorisation: "Habilitations & autorisations",
					manager_data: "Données du représentant légal",
					office_coordinates: "Coordonnées du cabinet",
					bank_data: "Données bancaires (commissions)",
					office_products: "Les produits auxquels le cabinet a accès",
					check_all_spci: "Cocher toutes les SCPI",
					form: {
						company_name: {
							label: "Raison sociale *",
							placeholder: "Raison sociale",
						},
						siret: {
							label: "Numéro d'identification de l'entreprise (SIRET, RIDET, T.A.H.I.T.I) *",
							placeholder: "Numéro d'identification de l'entreprise",
						},
						legal_form: {
							label: "Forme juridique *",
							placeholder: "Choisir",
						},
						rcs: {
							label: "RCS *",
							placeholder: "Lieu du RCS",
						},
						share_capital: {
							label: "Capital social *",
							placeholder: "Montant du capital social",
						},
						type_advisor: {
							label: "Type du conseiller *",
							placeholder: "Choisir le type",
						},
						habilitations: {
							label: "Habilitations *",
						},
						professional_association: {
							label: "Association professionnelle *",
							placeholder: "Entrer l'association",
						},
						numero_orias: {
							label: "N°Orias *",
							placeholder: "N°Orias",
						},
						business_lines: {
							label: "Ligne de metier *",
						},
						name: {
							label: "Nom *",
							placeholder: "Nom",
						},
						first_name: {
							label: "Prénom *",
							placeholder: "Prénom",
						},
						birth_date: {
							label: "Date de naissance *",
						},
						email_manager: {
							label: "Email du gérant *",
							placeholder: "Indiquer l'email",
						},
						email_administratif: {
							label: "Email administratif",
							placeholder: "Indiquer l'email",
						},
						phone_number: {
							label: "N° de téléphone fixe",
							placeholder: "N° de téléphone fixe",
						},
						address: {
							label: "Adresse (n° + rue + ville + code postal) *",
							placeholder: "Adresse (n° + rue + ville + code postal)",
						},
						account_owner: {
							label: "Titulaire du compte *",
							placeholder: "Titulaire du compte",
						},
						iban: {
							label: "IBAN *",
							placeholder: "IBAN",
						},
						bic: {
							label: "BIC *",
							placeholder: "BIC",
						},
					},
				},
				create_cgp: {
					title: "Création d'un CGP",
					data_advisor: "Données du conseiller",
					partner_account: "Compte partenaire",
					message_no_office_selected: "Aucun cabinet n'a été créé ou selectionné, veuillez revenir à l'étape précédente",
					fill_with_manager_data: "Remplir avec les données du représentant légal",
					form: {
						last_name: {
							label: "Nom *",
							placeholder: "Nom",
						},
						first_name: {
							label: "Prénom *",
							placeholder: "Prénom",
						},
						birth_date: {
							label: "Date de naissance *",
						},
						email: {
							label: "Email principal (adresse email de connexion) *",
							placeholder: "Indiquer l'email",
						},
						phone_number: {
							label: "N° de téléphone mobile *",
							placeholder: "N° de téléphone mobile",
						},
						etat_compte: {
							label: "Etat du compte *",
							placeholder: "Etat du compte",
						},
						signature_date: {
							label: "Date de signature *",
						},
						creation_date: {
							label: "Date de création *",
						},
					},
				},
			},
			back: "Retour au Middle Office",
		},
	},
	component: {
		menu: {
			question: "Une question ?",
			contact: "Contacter Wenimmo",
		},
		signature_alert_banner: "Enregistrez votre signature manuscrite pour simplifier et accélérer vos souscriptions",
		form: {
			optional: "facultatif",
			apportCompteCourant: "Précisez l'origine de l'apport en compte courant",
			paymentByTier: "Le paiement est effectué par un tiers",
			paymentByTierMotive: "Précisez le motif",
			paymentFromFrenchAccount: "Le paiement provient d'un compte français",
			paymentFromCountry: "Précisez le pays",
			civility: "Civilité",
			last_name: "Nom",
			first_name: "Prénom(s)",
			maiden_name: "Nom de jeune fille",
			email: "E-mail",
			office_name: "Mon cabinet",
			mobile_phone: "Téléphone portable",
			spouse: {
				first_name: "Prénom du conjoint",
				last_name: "Nom du conjoint",
				email: "E-mail du conjoint",
				mobile_phone: "Téléphone portable du conjoint",
				birth_date: "Date de naissance du conjoint",
				birth_place: "Lieu de naissance du conjoint",
			},
			address: "Adresse",
			address_automatique: "Adresse (recherche automatique)",
			address_automatique_tooltip: "Pour les TOM, nous vous invitons à indiquer l'adresse manuellement et ne pas utiliser la recherche automatique ",
			postal_code: "Code postal",
			city: "Ville",
			country: "Pays",
			nationality: "Nationalité",
			marital_status: "Situation familiale",
			matrimonial_regime: "Régime matrimonial",
			birth_date: "Date de naissance",
			birth_place: "Lieu de naissance",
			birth_department: "Département de naissance (numéro du département)",
			birth_country: "Pays de naissance",
			profession: "Profession (si retraité ancienne profession)",
			line_of_businness: "Secteur d'activité",
			category_social_profession: "Catégorie socio-professionnelle",
			fiscal_regime: "Régime fiscal",
			nif: "Numéro d'Identification Fiscale (NIF)",
			tax_residence_country: "Pays de résidence fiscale",
			legal_capacity: "Capacité juridique",
			politically_exposed_person: "Personne politiquement exposée",
			us_person: "US Person",
			agreement_electronic_document:
				"Accord pour envoi des documents dématérialisés par voie électronique (bordereau fiscal / distribution de revenus / convocations aux AG / publication règlementaire…)*",
			non_professional_classification_acknowledgment:
				"Le ou les souscripteur(s) est(sont) informé(s) que la société de gestion classe les souscripteurs dans la catégorie « client non professionnel » leur permettant ainsi de bénéficier du régime de protection le plus favorable.",
			message: {
				info: {
					recommandation_rib: "Co-souscription recommandée avec RIB compte joint SAUF si réemploi de fonds propres (attestation à fournir)",
				},
				warning: {
					next_step: {
						text: "Afin de passer à l'étape suivante",
						mailTo: "Contactez Wenimmo",
					},
					age_restriction_old: "Votre client a plus de 80 ans : il est possible qu'un document complémentaire vous soit demandé",
					age_restriction_minor: "Votre client est mineur : il est possible qu'un document complémentaire vous soit demandé",
					tax_residence_country_france: "Si différent de France : Merci de prendre contact avec Wenimmo",
					non_professional_classification_acknowledgment: "Nous vous invitons à contacter Wenimmo afin de classer votre client en « client professionnel »",
				},
			},
			payment_methods: {
				description: "Méthode de paiement choisie : ",
				titulary_name: "Nom du titulaire",
				bank_domiciliation: "Domiciliation bancaire",
				iban: "IBAN",
				bic: "BIC",
				organism_name: "Nom de l'organisme",
				download_rib: "Télécharger le RIB",
				nantie_parts: "Les parts seront-elles nanties ?",
				loan_amount: "Montant du prêt",
			},
			funds_origin: "Origine des fonds",
			funds_origin_other: "Veuillez préciser",
			funds_origin_savings: "Précisez l'origine de l'épargne",
			financial_objective: "Objectif d'investissement",
			signature_place: "Lieu de signature (en France uniquement)",
			community_investment: {
				question: "En cas de régime de communauté, merci de précisez si les souscriptions sont effectuées avec : ",
				co_subscription_recommendation: "co-souscription recommandée",
			},
			community_funds_origin: "Origine des fonds propres",
			community_funds_origin_other: "Si autre, précisez",
			corporation: {
				raison_social: "Raison sociale",
				siret: "Siret",
				forme_juridique: "Forme juridique",
				addresse: "Addresse du siège social",
				residence_fiscale: "Résidence fiscale",
				residence_fiscale_other: "Précisez",
				regime_fiscal: "Régime fiscal",
				assujetti_ifi: "Assujetti à l'IFI",
				qualite_representant_legal: "Qualité du représentant légal",
			},
		},
		deposit_document: {
			deposit_documents: "Déposer des documents",
			or_drag_drop: "Ou glissez-déposez vos fichiers ici",
			file_not_autorized: "Fichier non autorisé",
			tooltip_texts: {
				rib: "Si co-souscription, RIB commun recommandé.\n\nSi souscription seule, RIB au nom seul du souscripteur recommandé si fonds propres.",
				funds_origin: `Si 'Revenus professionnels' => justificatif des revenus (bulletin de salaire/avis d'imposition…) + dernier relevé de compte\n\nSi 'Epargne' => relevé d'épargne + dernier relevé de compte\n\nSi 'Emprunt' => copie de l'offre de prêt acceptée et signée\n\nSi 'Donation' => copie de l'acte notarié ou de la déclaration enregistrée auprès des services fiscaux + relevés de comptes justifiant la traçabilité des fonds\n\nSi 'Succession' => copie de l'acte de succession + relevés de comptes justifiant la traçabilité des fonds\n\nSi 'Cession d'actifs de valeurs mobilières' => acte de cession + relevés de compte justifiant la traçabilité des fonds\n\nSi 'Cession d'actifs immobiliers' => acte de cession avec prix + relevés de compte justifiant la traçabilité des fonds\n\nSi 'Rachat d'un contrat d'Assurance-Vie' => copie de la confirmation de rachat + relevés de compte justifiant la traçabilité des fonds\n\nSi 'Autes : Précisez" => justificatif + relevés de compte justifiant la traçabilité des fonds`,
				domicil_proof:
					"Justificatif de Domicile acceptés :\n\nAssurance habitation ou factures de moins de 3 mois : électricité / gaz / eau / box internet / téléphone\n\nAvis d'imposition acceptés :\n> moins de 3 mois pour ADVENIS / AESTIAM / KYANEOS\n> moins de 6 mois pour ALTAREA / EURYALE\n> moins de 1 an pour les autres sociétés de gestion)",
			},
		},
		tooltip: {
			appreciation_client:
				"Comment êtes-vous entré en relation, quelle est son activité, pourquoi réside-t-il à l'étranger le cas échéant ? Personne morale, tous les associés sont-ils identifiés ?",
			appreciation_operation: "Perspective de l'opération, vous paraît-elle normale et cohérente et pourquoi ? Si les fonds proviennent d'un tiers, quel en est le motif ?",
			non_professional_classification_acknowledgment:
				"Vous pouvez formuler une demande écrite WENIMMO afin d'être classé en « client professionnel » et renoncer à une partie de la protection. Dans ce cas, nous vous remercions de contacter WENIMMO à backoffice@wenimmo.com",
		},
		revenu_charge: {
			nature: "Nature",
			montant: "Montant",
			add: "Ajouter",
		},
	},
};

export default trads;
